import {
  DOCS_CHANGE_PAGE,
  DOCS_CHANGE_LIST_VIEW,
  DOCS_VIEW_DETAIL,
  DOCS_DETAIL,
  DOCS_SEARCH,
  DOCS_DATA,
  DOCS_POPULATION,
  DOCS_SECTIONS,
  DOCS_COMPONENTS,
  DOCS_DRIVERS,
  DOCS_TERRITORIES,
  DOCS_CLEAR,
  DOCS_ACTION_LEVELS,
  DOCS_CLEAR_FILTERS,
  DOCS_VIEW_GRAPH,
  DOCS_LIST_TYPE,
  DOCS_AUTHOR,
  DOCS_YEAR,
  DOCS_KEYWORDS_SHOW,
} from "../types/docsTypes";

export const docsChangePage = (page) => (dispatch, getState) => {
  const { docs_list, docs_items_per_page } = getState().docsReducer;
  const element = document.getElementById("docs-container");
  let init = (page - 1) * docs_items_per_page;
  let end = page * docs_items_per_page;
  let view_data = docs_list.slice(init, end);
  dispatch({
    type: DOCS_CHANGE_PAGE,
    payload: page,
  });
  dispatch({
    type: DOCS_CHANGE_LIST_VIEW,
    payload: view_data,
  });
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

export const docsViewDetail = (status, cod_doc) => (dispatch, getState) => {
  const { documents } = getState().defaultReducer;
  let file = documents.filter(
    (item) => item.id === parseInt(cod_doc) || item.slug === cod_doc
  );
  dispatch({
    type: DOCS_VIEW_DETAIL,
    payload: status,
  });
  dispatch({
    type: DOCS_DETAIL,
    payload: file,
  });
  if (status) {
    setTimeout(() => {
      const element = document.getElementById("doc-detail");
      element.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
        block: "start",
      });
    }, 25);
  }
};

export const searchKeywords = (value) => (dispatch, getState) => {
  const { docs_keywords } = getState().docsReducer;
  let keywords = [];

  if (value.length >= 2) {
    keywords = docs_keywords.filter(
      (key) => key.searchable.indexOf(value) >= 0
    );
  }

  dispatch({
    type: DOCS_KEYWORDS_SHOW,
    payload: keywords,
  });
};

export const searchDocuments = (value) => (dispatch, getState) => {
  const { documents } = getState().defaultReducer;
  const { docs_items_per_page } = getState().docsReducer;
  let data = {};

  if (value.length >= 4) {
    let items = [];

    documents.filter((item) => {
      if (item.searchable && item.searchable.indexOf(value) >= 0) {
        items.push(item);
      }
      return null;
    });

    data = {
      docs: items,
      docs_view: items.slice(0, docs_items_per_page),
    };
  } else {
    data = {
      docs: documents,
      docs_view: documents.slice(0, docs_items_per_page),
    };
  }

  dispatch({
    type: DOCS_DATA,
    payload: data,
  });
};

export const docsSearch = (value) => (dispatch) => {
  dispatch({
    type: DOCS_SEARCH,
    payload: value,
  });

  value = value.toLowerCase();
  dispatch(searchKeywords(value));
  dispatch(searchDocuments(value));
};

export const docFilterPopulations = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_population_select } = getState().docsReducer;
  const { filter_population } = getState().defaultReducer;

  const isAdded = docs_population_select.find(
    (item) => parseInt(item.id) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = filter_population.find(
      (item) => parseInt(item.value) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_population_select.push(item);
  }

  dispatch({
    type: DOCS_POPULATION,
    payload: docs_population_select,
  });
};

export const docFilterRemovePopulation = (index) => (dispatch, getState) => {
  const { docs_population_select } = getState().docsReducer;
  docs_population_select.splice(index, 1);

  dispatch({
    type: DOCS_POPULATION,
    payload: docs_population_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterSections = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_sections_select } = getState().docsReducer;
  const { filter_sections } = getState().defaultReducer;

  const isAdded = docs_sections_select.find(
    (item) => parseInt(item.value) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = filter_sections.find(
      (item) => parseInt(item.value) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_sections_select.push(item);
  }

  dispatch({
    type: DOCS_SECTIONS,
    payload: docs_sections_select,
  });
};

export const docFilterRemoveSection = (index) => (dispatch, getState) => {
  const { docs_sections_select } = getState().docsReducer;
  docs_sections_select.splice(index, 1);

  dispatch({
    type: DOCS_SECTIONS,
    payload: docs_sections_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterComponents = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_components_select } = getState().docsReducer;
  const { filter_components } = getState().defaultReducer;

  const isAdded = docs_components_select.find(
    (item) => parseInt(item.value) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = filter_components.find(
      (item) => parseInt(item.value) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_components_select.push(item);
  }

  dispatch({
    type: DOCS_COMPONENTS,
    payload: docs_components_select,
  });
};

export const docFilterRemoveComponent = (index) => (dispatch, getState) => {
  const { docs_components_select } = getState().docsReducer;
  docs_components_select.splice(index, 1);

  dispatch({
    type: DOCS_COMPONENTS,
    payload: docs_components_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterDrivers = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_drivers_select } = getState().docsReducer;
  const { filter_drivers } = getState().defaultReducer;

  const isAdded = docs_drivers_select.find(
    (item) => parseInt(item.value) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = filter_drivers.find(
      (item) => parseInt(item.value) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_drivers_select.push(item);
  }

  dispatch({
    type: DOCS_DRIVERS,
    payload: docs_drivers_select,
  });
};

export const docFilterRemoveDriver = (index) => (dispatch, getState) => {
  const { docs_drivers_select } = getState().docsReducer;
  docs_drivers_select.splice(index, 1);

  dispatch({
    type: DOCS_DRIVERS,
    payload: docs_drivers_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterTerritories = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_territories_select } = getState().docsReducer;
  const { cities } = getState().defaultReducer;
  const document_cities = cities.filter((item) => item.is_document === 1);

  const isAdded = docs_territories_select.find(
    (item) => item.id === cod_filter
  );

  if (!isAdded) {
    let item = document_cities.find(
      (item) => parseInt(item.id) === parseInt(cod_filter)
    );
    if (!item && cod_filter !== "nacional") return null;

    if (cod_filter === "nacional") {
      item = {
        id: "nacional",
        name: "Nacional",
      };
    }

    docs_territories_select.push(item);
  }

  dispatch({
    type: DOCS_TERRITORIES,
    payload: docs_territories_select,
  });
};

export const docFilterRemoveTerritory = (index) => (dispatch, getState) => {
  const { docs_territories_select } = getState().docsReducer;
  docs_territories_select.splice(index, 1);

  dispatch({
    type: DOCS_TERRITORIES,
    payload: docs_territories_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterActionLevels = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_action_levels_select } = getState().docsReducer;
  const { filter_action_levels } = getState().defaultReducer;

  const isAdded = docs_action_levels_select.find(
    (item) => parseInt(item.id) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = filter_action_levels.find(
      (item) => parseInt(item.value) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_action_levels_select.push(item);
  }

  dispatch({
    type: DOCS_ACTION_LEVELS,
    payload: docs_action_levels_select,
  });
};

export const docFilterRemoveActionLevel = (index) => (dispatch, getState) => {
  const { docs_action_levels_select } = getState().docsReducer;
  docs_action_levels_select.splice(index, 1);

  dispatch({
    type: DOCS_ACTION_LEVELS,
    payload: docs_action_levels_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterActionAuthors = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_author_select } = getState().docsReducer;
  const { authors } = getState().defaultReducer;

  const isAdded = docs_author_select.find(
    (item) => parseInt(item.id) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = authors.find(
      (item) => parseInt(item.id) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_author_select.push(item);
  }

  dispatch({
    type: DOCS_AUTHOR,
    payload: docs_author_select,
  });
};

export const docFilterRemoveAuthor = (index) => (dispatch, getState) => {
  const { docs_author_select } = getState().docsReducer;
  docs_author_select.splice(index, 1);

  dispatch({
    type: DOCS_AUTHOR,
    payload: docs_author_select,
  });

  dispatch(docsSearchByFilters());
};

export const docFilterActionYears = (cod_filter) => (dispatch, getState) => {
  if (!cod_filter) return null;
  const { docs_year_select } = getState().docsReducer;
  const { years } = getState().defaultReducer;

  const isAdded = docs_year_select.find(
    (item) => parseInt(item.id) === parseInt(cod_filter)
  );

  if (!isAdded) {
    const item = years.find(
      (item) => parseInt(item.id) === parseInt(cod_filter)
    );
    if (!item) return null;

    docs_year_select.push(item);
  }

  dispatch({
    type: DOCS_YEAR,
    payload: docs_year_select,
  });
};

export const docFilterRemoveYear = (index) => (dispatch, getState) => {
  const { docs_year_select } = getState().docsReducer;
  docs_year_select.splice(index, 1);

  dispatch({
    type: DOCS_YEAR,
    payload: docs_year_select,
  });

  dispatch(docsSearchByFilters());
};

export const docsFilters = (type, cod_filter) => (dispatch) => {
  if (type === "population") {
    dispatch(docFilterPopulations(cod_filter));
  }
  if (type === "sections") {
    dispatch(docFilterSections(cod_filter));
  }
  if (type === "components") {
    dispatch(docFilterComponents(cod_filter));
  }
  if (type === "driver") {
    dispatch(docFilterDrivers(cod_filter));
  }
  if (type === "territories") {
    dispatch(docFilterTerritories(cod_filter));
  }
  if (type === "action_levels") {
    dispatch(docFilterActionLevels(cod_filter));
  }
  if (type === "author") {
    dispatch(docFilterActionAuthors(cod_filter));
  }
  if (type === "year") {
    dispatch(docFilterActionYears(cod_filter));
  }

  dispatch(docsSearchByFilters());
};

export const docsSearchByFilters = () => (dispatch, getState) => {
  const {
    docs_population_select,
    docs_sections_select,
    docs_components_select,
    docs_drivers_select,
    docs_territories_select,
    docs_action_levels_select,
    docs_items_per_page,
    docs_author_select,
    docs_year_select,
  } = getState().docsReducer;
  const { documents } = getState().defaultReducer;

  let filter = [];
  if (docs_population_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];
    toFilter.forEach((document) => {
      if (document.population) {
        const ifExist = docs_population_select.filter(
          (item) =>
            document.population.split(",").indexOf(item.value.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_sections_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];
    toFilter.forEach((document) => {
      if (document.sections) {
        const ifExist = docs_sections_select.filter(
          (item) =>
            document.sections.split(",").indexOf(item.value.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_components_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];
    toFilter.forEach((document) => {
      if (document.components) {
        const ifExist = docs_components_select.filter(
          (item) =>
            document.components.split(",").indexOf(item.value.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_drivers_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];
    toFilter.forEach((document) => {
      if (document.drivers) {
        const ifExist = docs_drivers_select.filter(
          (item) =>
            document.drivers.split(",").indexOf(item.value.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_territories_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];
    toFilter.forEach((document) => {
      if (document.territories) {
        const ifExist = docs_territories_select.filter(
          (item) =>
            document.territories.split(",").indexOf(item.id.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_action_levels_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];

    toFilter.forEach((document) => {
      if (document.action_level) {
        const ifExist = docs_action_levels_select.filter(
          (item) =>
            document.action_level.split(",").indexOf(item.value.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_author_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];

    toFilter.forEach((document) => {
      if (document.year) {
        let ifExist = docs_author_select.filter((item) => {
          let authors = document.authors_active.filter(
            (author) => parseInt(author.id) === parseInt(item.id)
          );

          if (authors.length) {
            return document;
          }

          return null;
        });
        ifExist = ifExist.filter((item) => item);

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  if (docs_year_select.length) {
    const toFilter = filter.length > 0 ? filter : documents;
    const responses = [];

    toFilter.forEach((document) => {
      if (document.year) {
        const ifExist = docs_year_select.filter(
          (item) => document.year.split(",").indexOf(item.name.toString()) >= 0
        );

        if (ifExist.length) {
          responses.push(document);
        }
      }
    });

    filter = responses;
  }

  let docs_data = {};
  if (
    docs_population_select.length ||
    docs_sections_select.length ||
    docs_components_select.length ||
    docs_drivers_select.length ||
    docs_territories_select.length ||
    docs_action_levels_select.length ||
    docs_author_select.length ||
    docs_year_select.length
  ) {
    docs_data = {
      docs: filter,
      docs_view: filter.slice(0, docs_items_per_page),
    };
  } else {
    docs_data = {
      docs: documents,
      docs_view: documents.slice(0, docs_items_per_page),
    };
  }

  dispatch({
    type: DOCS_DATA,
    payload: docs_data,
  });
};

export const docsClear = () => (dispatch, getState) => {
  const { documents } = getState().defaultReducer;
  const { docs_items_per_page } = getState().docsReducer;
  let docs_data = {
    docs: documents,
    docs_view: documents.slice(0, docs_items_per_page),
  };
  dispatch({
    type: DOCS_CLEAR_FILTERS,
  });
  dispatch({
    type: DOCS_CLEAR,
    payload: docs_data,
  });
};

export const docsViewGraph = (payload) => (dispatch) => {
  dispatch({
    type: DOCS_VIEW_GRAPH,
    payload,
  });
};

export const changeDocsListType = (payload) => (dispatch) => {
  dispatch({
    type: DOCS_LIST_TYPE,
    payload,
  });
};
