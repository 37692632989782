import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getArticle } from "../functionality/actions/blogActions";
import ArticleLayout from "./layouts/ArticleLayout";
import ArticleResume from "../components/ArticleResume";
import loading from "../images/loading.svg";
import { getLabelRoute } from "../utils/GetRoute";

function ArticlePage(props) {
  const {
    match: {
      params: { article },
    },
    blogReducer: { blog_article, blog_loading },
    defaultReducer: { texts },
    getArticle,
  } = props;
  const menu6 = getLabelRoute(texts, "menu_6");

  useEffect(() => {
    getArticle(article);
  }, [article, getArticle]);

  return (
    <ArticleLayout>
      {blog_loading || !blog_article ? (
        <div className="Blog_loading">
          <img src={loading} alt="loading" />
          Cargando artículos...
        </div>
      ) : (
        <div className="Blog_article">
          <div className="Blog_article-detail-image">
            {blog_article.cover && (
              <img src={blog_article.cover} alt={blog_article.title} />
            )}
          </div>
          <div className="Blog_article-detail-content">
            <div className="Blog_article-header">
              <h1>{blog_article.title}</h1>
              <p className="Blog_article-date">
                Escrito el: {blog_article.created_at}
              </p>
            </div>
            {blog_article.categories && blog_article.categories.length ? (
              <div className="Blog_article-categories">
                <strong>
                  <span>Categorías:</span>
                </strong>
                {blog_article.categories.map((category) => (
                  <span key={category.id}>
                    <Link
                      to={`/${menu6.slug}/momentos/categoria/${category.slug}`}
                    >
                      {category.name};
                    </Link>
                  </span>
                ))}
              </div>
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: blog_article.content }} />
          </div>
          {blog_article.related && blog_article.related.length ? (
            <>
              <hr />
              <h2>Artículos relacionados:</h2>
              <div className="row">
                {blog_article.related.map((related) => (
                  <div key={related.id} className="col-md-6">
                    <ArticleResume article={related} />
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>
      )}
    </ArticleLayout>
  );
}

const mapStateToProps = ({ blogReducer, defaultReducer }) => {
  return {
    blogReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  getArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
