import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import image from "../images/bg-ecosystems.jpg";
import ListCities from "../components/ListCities";
import ButtonTutorial from "../components/ButtonTutorial";
import EcosystemStage from "../components/EcosystemStage";
import EcosystemSections from "../components/EcosystemSections";
import EcosystemCount from "../components/EcosystemCount";
import EcosystemPopulation from "../components/EcosystemPopulation";
import "./css/EcosystemsPage.css";
import * as ecosystemsActions from "../functionality/actions/ecosystemsActions";
import * as indexActions from "../functionality/actions/indexActions";
import EcosystemDocs from "../components/EcosystemDocs";
import ModalTutorialEcosystems from "../components/ModalTutorialEcosystems";
import SectionSlider from "../components/SectionSlider";
import ButtonGraph from "../components/ButtonGraph";

const {
  ecosystemSteps,
  ecosystemViewDocs,
  openCloseModal,
  ecosystemViewGraph,
} = ecosystemsActions;

const { changeMenu } = indexActions;
class EcosystemsPage extends Component {
  componentDidMount() {
    const { ecosystemSteps } = this.props;
    window.scrollTo(0, 0);
    if (this.props.match.params.id) {
      ecosystemSteps(this.props.match.params.id);
    } else {
      ecosystemSteps(null);
    }
  }

  componentDidUpdate(prevProps) {
    const { ecosystemSteps } = this.props;
    if (!this.props.match.params.id && prevProps.match.params.id) {
      ecosystemSteps(null);
    }
    if (
      this.props.match.params.id &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      ecosystemSteps(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    const { changeMenu, ecosystemViewGraph } = this.props;
    changeMenu("");
    ecosystemViewGraph(true);
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Ecosistemas de Iniciativas" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  render() {
    const {
      ecosystem_harsh,
      ecosystem_soft,
      ecosystem_ocupational,
      ecosystem_specific,
      ecosystem_general,
      ecosystem_vocational,
      ecosystem_intermediation_labor,
      ecosystem_intermediation_talent,
      ecosystem_orientation_labor,
      ecosystem_women,
      ecosystem_young_boys,
      ecosystem_disabality,
      ecosystem_total,
      ecosystem_indigenios,
      ecosystem_afro_descendant,
      ecosystem_conflict,
      ecosystem_reinstatement,
      ecosystem_poverty,
      ecosystem_orientation_talent,
      ecosystem_half,
      ecosystem_postmedia,
      ecosystem_intermediation,
      ecosystem_job,
      ecosystem_view_docs,
      ecosystes_doc_title,
      ecosystem_docs,
      ecosystem_modal,
      ecosystem_view_graph,
      ecosystem_lgbtiq,
    } = this.props.ecosystemsReducer;

    const { ecosystemViewDocs, openCloseModal, ecosystemViewGraph } =
      this.props;

    const { cities, other_contents, ecosystem_titles, sliders } =
      this.props.defaultReducer;

    let ecosystem = other_contents.filter(
      (item) => item.label === "ecosistemas_iniciativas"
    );
    let slider = sliders.filter(
      (item) => item.name === "ecosistemas_inciativas"
    );
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("ecosistemas_grafico") >= 0
    );

    return (
      <LayoutDefault>
        <ModalTutorialEcosystems
          visible={ecosystem_modal}
          openCloseModal={openCloseModal}
        />
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div className="title-section">{ecosystem[0].title}</div>
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-yellow"
              dangerouslySetInnerHTML={{ __html: ecosystem[0].value }}
            />
          </div>
        </div>
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" && (
          <div className="">
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#F59C01"
                  name={graphic[0].value}
                  action={() => ecosystemViewGraph(!ecosystem_view_graph)}
                  status={ecosystem_view_graph}
                />
                {ecosystem_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <ButtonTutorial onClick={() => openCloseModal("active")} />
        </div>
        <div className="ecosystems-container">
          <div className="ecosystems-content">
            <ListCities
              data={cities}
              to="ecosistemas-iniciativas"
              active="yellow"
              title={ecosystem_titles[0].value}
            />
            <div className="ecosystems-box-stages" id="ecosystems-stages">
              <EcosystemStage
                ecosystem_harsh={ecosystem_harsh}
                ecosystem_soft={ecosystem_soft}
                ecosystem_ocupational={ecosystem_ocupational}
                ecosystem_specific={ecosystem_specific}
                ecosystem_general={ecosystem_general}
                ecosystem_vocational={ecosystem_vocational}
                ecosystem_intermediation_labor={ecosystem_intermediation_labor}
                ecosystem_intermediation_talent={
                  ecosystem_intermediation_talent
                }
                ecosystem_orientation_labor={ecosystem_orientation_labor}
                ecosystem_orientation_talent={ecosystem_orientation_talent}
                action={ecosystemViewDocs}
                cod_city={this.props.match.params.id}
                titles={ecosystem_titles}
              />
              <div className="ecosystems-box-count">
                <EcosystemSections
                  ecosystem_total={ecosystem_total}
                  ecosystem_half={ecosystem_half}
                  ecosystem_postmedia={ecosystem_postmedia}
                  ecosystem_intermediation={ecosystem_intermediation}
                  ecosystem_job={ecosystem_job}
                  titles={ecosystem_titles}
                />
                <EcosystemCount
                  total={ecosystem_total}
                  title={ecosystem_titles[22].value}
                />
              </div>
            </div>
          </div>
          <div className="ecosystems-charts">
            <EcosystemPopulation
              doughnut={this.props.ecosystemsReducer.chart_1}
              ecosystem_total={ecosystem_total}
              ecosystem_women={ecosystem_women}
              ecosystem_young_boys={ecosystem_young_boys}
              ecosystem_disabality={ecosystem_disabality}
              ecosystem_indigenios={ecosystem_indigenios}
              ecosystem_afro_descendant={ecosystem_afro_descendant}
              ecosystem_conflict={ecosystem_conflict}
              ecosystem_reinstatement={ecosystem_reinstatement}
              ecosystem_poverty={ecosystem_poverty}
              ecosystem_lgbtiq={ecosystem_lgbtiq}
              action={ecosystemViewDocs}
              cod_city={this.props.match.params.id}
              titles={ecosystem_titles}
            />
            {ecosystem_titles[38] && ecosystem_titles[38].value && (
              <p className="text-note text-left">
                {ecosystem_titles[38].value}
              </p>
            )}
          </div>
          <div id="ecosystems-docs">
            {ecosystem_view_docs && (
              <div className="ecosystems-docs">
                <div className="ecosystem-title">
                  <span className="icon-dot" />
                  {`${ecosystem_titles[32].value} ${ecosystes_doc_title}`}
                </div>
                <EcosystemDocs data={ecosystem_docs} />
              </div>
            )}
          </div>
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ defaultReducer, ecosystemsReducer }) => {
  return {
    defaultReducer,
    ecosystemsReducer,
  };
};

const mapDispatchToProps = {
  ecosystemSteps,
  ecosystemViewDocs,
  changeMenu,
  openCloseModal,
  ecosystemViewGraph,
};

export default connect(mapStateToProps, mapDispatchToProps)(EcosystemsPage);
