import {
  MODAL_CHANGE_STATUS,
  MODAL_CHANGE_TYPE,
  MODAL_CLEAR,
  MODAL_CHANGE_TITLE,
  MODAL_CHANGE_NAME,
  MODAL_CHANGE_EMAIL,
  MODAL_CHANGE_INSTITUTION,
  MODAL_CHANGE_PHONE,
  MODAL_CHANGE_MESSAGE,
  MODAL_CHANGE_REQUEST,
  MODAL_CHANGE_SECTION,
  MODAL_ERROR,
  MODAL_SUCCESS,
  MODAL_LOADED,
  MODAL_LINK,
  MODAL_SURVEY,
} from "../types/modalTypes";
import Api from "../Api";

export const modalChangeStatus =
  (status, type, file = null, survey = null) =>
  (dispatch, getState) => {
    const { user } = getState().userReducer;

    if (status === "visible") {
      if (user) {
        dispatch(modalChangeName(user.name));
        dispatch(modalChangeEmail(user.email));
        dispatch(modalChangeInstitution(user.entity));
        dispatch(modalChangePhone(user.phone));
      }

      if (file) {
        dispatch(modalChangeLink(file));
      }
      if (survey) {
        dispatch(modalChangeSurvey(survey));
      }

      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      dispatch({
        type: MODAL_CLEAR,
      });
      dispatch(modalChangeLink(""));
    }

    dispatch({
      type: MODAL_CHANGE_STATUS,
      payload: status,
    });
    dispatch({
      type: MODAL_CHANGE_TYPE,
      payload: type,
    });
  };

export const modalChangeTitle = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_TITLE,
    payload: value,
  });
};

export const modalChangeName = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_NAME,
    payload: value,
  });
};

export const modalChangeEmail = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_EMAIL,
    payload: value,
  });
};

export const modalChangeInstitution = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_INSTITUTION,
    payload: value,
  });
};

export const modalChangePhone = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_PHONE,
    payload: value,
  });
};

export const modalChangeMessage = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_MESSAGE,
    payload: value,
  });
};

export const modalChangeSection = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_SECTION,
    payload: value,
  });
};

export const modalChangeRequest = (value) => (dispatch) => {
  dispatch({
    type: MODAL_CHANGE_REQUEST,
    payload: value,
  });
};

export const modalChangeLink = (payload) => (dispatch) => {
  dispatch({
    type: MODAL_LINK,
    payload,
  });
};

export const modalChangeSurvey = (payload) => (dispatch) => {
  dispatch({
    type: MODAL_SURVEY,
    payload,
  });
};

export const saveDocumentDownload = (title, section, request) => () => {
  let data = {
    title,
    name: "",
    email: "",
    institution: "",
    phone: "",
    message: "",
    section,
    request,
  };

  Api.callMethod("document-request", "POST", data, function () {});
};

export const modalDocumentRequest =
  (title, section, request, file = null, survey = null) =>
  (dispatch, getState) => {
    const {
      modal_download_type,
      modal_name,
      modal_email,
      modal_institution,
      modal_phone,
      modal_message,
    } = getState().modalReducer;

    let showError = (error) => {
      dispatch({
        type: MODAL_ERROR,
        payload: error,
      });
    };

    if (
      modal_download_type === 1 &&
      (modal_name === "" ||
        modal_email === "" ||
        modal_institution === "" ||
        modal_phone === "" ||
        modal_message === "")
    ) {
      showError(
        "Todos los campos son obligatorios, verifica que estén escritos."
      );
      return;
    }
    if (
      modal_download_type === 0 &&
      (modal_name === "" || modal_email === "" || modal_institution === "")
    ) {
      showError(
        "Todos los campos son obligatorios, verifica que estén escritos."
      );
      return;
    }
    showError("");
    dispatch({
      type: MODAL_LOADED,
      payload: true,
    });
    let data = {
      title,
      name: modal_name,
      email: modal_email,
      institution: modal_institution,
      phone: modal_phone,
      message: modal_message,
      section,
      request,
    };
    Api.callMethod("document-request", "POST", data, function (res) {
      setTimeout(function () {
        dispatch({
          type: MODAL_LOADED,
          payload: false,
        });
      }, 3500);
      if (res.error.code === 0) {
        let response =
          request === "Solicitud"
            ? "En los próximos días Fundación Corona se pondrá en contacto con usted para tener la información necesaria y así poder compartirle el documento.\n¡Gracias!"
            : "";

        if (response === "") {
          if (file) {
            dispatch(modalChangeLink(file));
          }
          if (survey) {
            dispatch(modalChangeSurvey(survey));
          }
        }
        dispatch({
          type: MODAL_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: MODAL_SUCCESS,
          payload: "Lo sentimos, hubo un error vuelve a intentarlo más tarde.",
        });
      }
    });
  };
