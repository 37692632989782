import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import LayoutDefault from "./LayoutDefault";
import currentImage from "../../images/bg-news.jpg";
import SectionSlider from "../../components/SectionSlider";
import "./css/Blog.css";
import { getLabelRoute } from "../../utils/GetRoute";

function BlogLayout(props) {
  const {
    children,
    defaultReducer: { sliders, other_contents, texts },
  } = props;
  const menu2 = getLabelRoute(texts, "menu_2");
  const menu5 = getLabelRoute(texts, "menu_5");
  const menu6 = getLabelRoute(texts, "menu_6");
  const [title, setTitle] = useState("");
  const [slider, setSlider] = useState(null);

  useEffect(() => {
    if (!slider) {
      let sliderInfo = sliders.find((item) => item.name === "noticias");
      if (sliderInfo) {
        setSlider(sliderInfo);
      }
    }
  }, [slider, sliders]);

  useEffect(() => {
    let content = other_contents.find((item) => item.label === "noticias");
    setTitle(content ? content.title : "");
  }, [other_contents]);

  return (
    <LayoutDefault>
      {!slider || slider.is_active !== 1 || !slider.slides.length ? (
        <div
          className="bg-section"
          style={{ backgroundImage: `URL(${currentImage})` }}
        >
          <div
            className="bg-section"
            style={{ backgroundImage: `URL(${currentImage})` }}
          >
            <div className="title-section">{title}</div>
          </div>
        </div>
      ) : (
        <SectionSlider slides={slider.slides} />
      )}
      <div className="Blog_menu">
        <NavLink exact to={`/${menu6.slug}`} activeClassName="active">
          ¿Qué es el AIL?
        </NavLink>
        <NavLink to={`/${menu2.slug}`}>Herramientas</NavLink>
        <NavLink to={`/${menu5.slug}`}>Documentos</NavLink>
        <NavLink to={`/${menu6.slug}/momentos`} activeClassName="active">
          Momentos
        </NavLink>
      </div>
      {children}
    </LayoutDefault>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(mapStateToProps, null)(BlogLayout);
