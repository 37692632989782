import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getArticles,
  getArticlesByCategory,
} from "../functionality/actions/blogActions";
import ArticleLayout from "./layouts/ArticleLayout";
import BlogPagination from "../components/BlogPagination";
import loading from "../images/loading.svg";
import ArticleResume from "../components/ArticleResume";

function BlogsPage(props) {
  const {
    match: {
      url,
      params: { category, page },
    },
    blogReducer: {
      blog_categories,
      blog_loading,
      blog_total_pages,
      blog_articles,
    },
    getArticles,
    getArticlesByCategory,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const currentPage = page ? parseInt(page) : 1;
    setCurrentPage(currentPage);

    if (!category) {
      getArticles(currentPage);
    } else if (blog_categories.length) {
      getArticlesByCategory(category, currentPage);
    }
  }, [page, category, blog_categories, getArticles, getArticlesByCategory]);

  return (
    <ArticleLayout>
      {blog_loading ? (
        <div className="Blog_loading">
          <img src={loading} alt="loading" />
          Cargando artículos...
        </div>
      ) : (
        <div className="row">
          {!blog_articles.length && (
            <h2>Actualmente, no hay artículos disponibles.</h2>
          )}
          {blog_articles.map((article) => (
            <div key={article.id} className="col-md-6 mb-4">
              <ArticleResume article={article} />
            </div>
          ))}
          <div className="col-md-12">
            <BlogPagination
              url={url}
              currentPage={currentPage}
              totalPages={blog_total_pages}
            />
          </div>
        </div>
      )}
    </ArticleLayout>
  );
}

const mapStateToProps = ({ blogReducer }) => {
  return {
    blogReducer,
  };
};

const mapDispatchToProps = {
  getArticles,
  getArticlesByCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsPage);
