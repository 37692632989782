import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ButtonDownload from "./ButtonDownlaod";
import loading from "../images/loading.svg";
import "./css/ModalDownload.css";
import {
  modalChangeStatus,
  modalChangeName,
  modalChangeEmail,
  modalChangeInstitution,
  modalChangePhone,
  modalChangeMessage,
  modalDocumentRequest,
  saveDocumentDownload,
} from "../functionality/actions/modalActions";

function ModalDownload(props) {
  const {
    defaultReducer: { other_contents, texts },
    modalChangeStatus,
    modalChangeName,
    modalChangeEmail,
    modalDocumentRequest,
    modalChangeInstitution,
    modalChangePhone,
    modalChangeMessage,
    saveDocumentDownload,
  } = props;
  const {
    modal_download_status,
    modal_download_type,
    modal_name,
    modal_email,
    modal_institution,
    modal_phone,
    modal_message,
    modal_error,
    modal_loading,
    modal_success,
    modal_link,
    modal_survey,
  } = props.modalReducer;
  let classView = modal_download_type === 0 ? "" : "private";
  let request =
    props.button_name === "SOLICITAR DOCUMENTO" ? "Solicitud" : "Descarga";

  let linkTerms = other_contents.find(
    (item) => item.label === "tratamiento_datos"
  );
  linkTerms = linkTerms
    ? linkTerms.value
    : "https://www.fundacioncorona.org/es/sobre-nosotros/tratamiento-de-datos";

  let surveyText = other_contents.find(
    (item) => item.label === "texto_encuesta"
  );
  surveyText = surveyText
    ? surveyText.value
    : "https://www.fundacioncorona.org/es/sobre-nosotros/tratamiento-de-datos";

  const returnText = (label) => {
    let text = texts.find((item) => item.label === label);
    text = text ? text.value : "";
    return text;
  };

  return ReactDOM.createPortal(
    <div className={`modal-download ${modal_download_status}`}>
      <div className={`modal-download-content ${modal_download_status}`}>
        <div className="modal-download-header">
          <ButtonDownload name={props.button_name} />
          <button
            className="modal-bownload-close"
            onClick={() => modalChangeStatus("", modal_download_type)}
          >
            X
          </button>
        </div>
        <div className="modal-download-box bg-gray-gradient">
          <div className={`${classView} modal-download-form`}>
            {modal_loading ? (
              <div className="modal-loaded">
                <img src={loading} alt="loading" />
                Enviando Solicitud...
              </div>
            ) : (
              <Fragment>
                <div className="modal-download-box-form">
                  {modal_success === "" ? (
                    <Fragment>
                      <h4>{returnText("descarga_titulo")}</h4>
                      {modal_error !== "" && (
                        <div className="alert alert-danger" role="alert">
                          {modal_error}
                        </div>
                      )}
                      <input
                        type="text"
                        placeholder={returnText("descarga_input_1")}
                        value={modal_name}
                        onChange={(input) =>
                          modalChangeName(input.target.value)
                        }
                      />
                      <input
                        type="text"
                        placeholder={returnText("descarga_input_2")}
                        value={modal_email}
                        onChange={(input) =>
                          modalChangeEmail(input.target.value)
                        }
                      />
                      {classView === "private" && (
                        <input
                          type="text"
                          placeholder={returnText("descarga_input_4")}
                          value={modal_phone}
                          onChange={(input) =>
                            modalChangePhone(input.target.value)
                          }
                        />
                      )}
                      <input
                        type="text"
                        placeholder={returnText("descarga_input_3")}
                        value={modal_institution}
                        onChange={(input) =>
                          modalChangeInstitution(input.target.value)
                        }
                      />
                      {classView === "private" && (
                        <textarea
                          placeholder={returnText("descarga_input_5")}
                          rows="3"
                          value={modal_message}
                          onChange={(input) =>
                            modalChangeMessage(input.target.value)
                          }
                        />
                      )}
                    </Fragment>
                  ) : modal_success === "download" ? (
                    <a
                      href={props.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-block btn-send"
                    >
                      Descargar Documento
                    </a>
                  ) : (
                    <h4>{modal_success}</h4>
                  )}
                  {modal_link !== "" && (
                    <a
                      href={modal_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-block btn-send"
                      onClick={() =>
                        saveDocumentDownload(
                          props.title,
                          props.section,
                          request
                        )
                      }
                    >
                      Abrir Documento
                    </a>
                  )}
                  {modal_survey !== "" && (
                    <a
                      href={modal_survey}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-block btn-survey"
                    >
                      {surveyText}
                    </a>
                  )}
                  <div className="modal-download-form-menu">
                    {modal_success === "" && (
                      <button
                        onClick={() =>
                          modalDocumentRequest(
                            props.title,
                            props.section,
                            request,
                            props.file,
                            props.survey
                          )
                        }
                      >
                        ACEPTAR
                      </button>
                    )}
                    <button
                      onClick={() => modalChangeStatus("", modal_download_type)}
                      className="bg-gray-gradient"
                    >
                      {modal_success === "" ? "CANCELAR" : "ACEPTAR"}
                    </button>
                  </div>
                </div>
                {classView === "private" ? (
                  <div className="private-message">
                    <h4>¡IMPORTANTE!</h4>
                    <div className="modal-download-message">
                      {returnText("descarga_tratamiento_datos")}
                      <br />
                      <br />
                      Política de datos: <br />
                      <a
                        href={linkTerms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linkTerms}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="modal-download-message">
                    {returnText("descarga_tratamiento_datos")}
                    <br />
                    <br />
                    Política de datos: <br />
                    <a
                      href={linkTerms}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {linkTerms}
                    </a>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

const mapStateToProps = ({ modalReducer, defaultReducer }) => {
  return {
    modalReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  modalChangeStatus,
  modalChangeName,
  modalChangeEmail,
  modalChangeInstitution,
  modalChangePhone,
  modalChangeMessage,
  modalDocumentRequest,
  saveDocumentDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownload);
