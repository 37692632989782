import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import image from "../images/bg-contact.png";
import "./css/ContactPage.css";
import {
  contactName,
  contactTerms,
  contactEmail,
  contactPhone,
  contactMessage,
  contactSend,
  contactClear,
} from "../functionality/actions/contactActions";
import { changeMenu } from "../functionality/actions/indexActions";
import SectionSlider from "../components/SectionSlider";

class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { changeMenu } = this.props;
    changeMenu("");
  }

  render() {
    const {
      contactName,
      contactTerms,
      contactEmail,
      contactPhone,
      contactMessage,
      contactSend,
      defaultReducer: { social_networks, sliders },
      contactReducer: {
        contact_name,
        contact_terms,
        contact_email,
        contact_phone,
        contact_message,
        contact_error,
        contact_success,
        contact_status,
      },
    } = this.props;
    let slider = sliders.find((item) => item.name === "conecta");

    return (
      <LayoutDefault>
        <div className="row">
          {!slider || !slider.slides.length ? (
            <div className="col-md-12">
              <div
                className="bg-section contact-bg"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div className="title-section">CONTACTO</div>
              </div>
            </div>
          ) : (
            <SectionSlider slides={slider.slides} />
          )}
          <div className="offset-md-2 col-md-8 mt-3">
            <div className="contact-form">
              {contact_success !== "" && (
                <div className="alert alert-success" role="alert">
                  {contact_success}
                </div>
              )}
              <label>Nombre y Apellidos:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre y Apellidos"
                value={contact_name}
                onChange={(input) => contactName(input.target.value)}
              />
              <label>Email:</label>
              <input
                type="text"
                className="form-control"
                placeholder="demo@demo.com"
                value={contact_email}
                onChange={(input) => contactEmail(input.target.value)}
              />
              <label>Teléfono:</label>
              <input
                type="number"
                className="form-control"
                placeholder="Teléfono"
                value={contact_phone}
                onChange={(input) => contactPhone(input.target.value)}
              />
              <label>Mensaje:</label>
              <textarea
                className="form-control"
                rows="6"
                placeholder="Mensaje..."
                value={contact_message}
                onChange={(input) => contactMessage(input.target.value)}
              />
              <label>
                <input
                  type="checkbox"
                  checked={contact_terms}
                  onChange={(input) => contactTerms(input.target.checked)}
                />
                Acepto la política de privacidad de datos
              </label>
              {contact_error !== "" && (
                <div className="alert alert-danger mt-3" role="alert">
                  {contact_error}
                </div>
              )}
              {!contact_status ? (
                <button
                  className="btn btn-block btn-send"
                  onClick={contactSend}
                >
                  ENVIAR
                </button>
              ) : (
                <button className="btn btn-block btn-send">ENVIANDO...</button>
              )}
              {social_networks.map((company) => (
                <div className="contact-social-networks" key={company.id}>
                  <h3>{company.name}</h3>
                  <div className="contact-social">
                    {company.social_networks.map((item) => (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={item.id}
                      >
                        <i className={item.icon}></i>
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ contactReducer, defaultReducer }) => {
  return {
    contactReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  contactName,
  contactTerms,
  contactEmail,
  contactPhone,
  contactMessage,
  contactSend,
  contactClear,
  changeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
