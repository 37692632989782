import React from "react";
import { connect } from "react-redux";
import BoxSearch from "./BoxSearch";
import FilterSelect from "./FilterSelect";
import "./css/InitiativeFilters.css";
import {
  initiativeSearch,
  initiativeFilter,
  initiativeRemoveFilterWinner,
  initiativeAddFilterStretch,
  initiativeAddFilterPopulation,
  initiativeRemoveFilterStretch,
  initiativeRemoveFilterPopulation,
  initiativeChangeWinnerFilter,
} from "../functionality/actions/initiativeActions";
import FilterItem from "./FilterItem";

function InitiativeNewFilters(props) {
  const {
    initiativeReducer: {
      initiative_stretches,
      initiative_populations,
      initiative_search,
      initiative_stretches_filter,
      initiative_population_filter,
      initiative_winner_filter,
      initiative_winners,
      initiative_keywords_show,
    },
    initiativeSearch,
    initiativeFilter,
    initiativeRemoveFilterWinner,
    initiativeAddFilterStretch,
    initiativeAddFilterPopulation,
    initiativeRemoveFilterStretch,
    initiativeRemoveFilterPopulation,
    initiativeChangeWinnerFilter,
  } = props;

  return (
    <div>
      <BoxSearch
        placeholder="BUSCADOR PALABRAS CLAVES"
        value={initiative_search}
        onChange={initiativeSearch}
        onBlur={initiativeFilter}
        keywords={initiative_keywords_show}
      />
      <div className="InitiativeFilters_filters">
        {initiative_winners.length ? (
          <FilterSelect
            title="CASOS DE ÉXITO"
            options={initiative_winners}
            value=""
            action={(type, value) => initiativeChangeWinnerFilter(value)}
            type="winners"
            color="yellow"
          />
        ) : null}
        {initiative_populations.length ? (
          <FilterSelect
            title="POBLACIONES"
            options={initiative_populations}
            value=""
            action={(type, value) => initiativeAddFilterPopulation(value)}
            type="population"
            color="yellow"
          />
        ) : null}
        {initiative_stretches.length ? (
          <FilterSelect
            title="TEMÁTICA"
            options={initiative_stretches}
            value=""
            action={(type, value) => initiativeAddFilterStretch(value)}
            type="stretch"
            color="yellow"
          />
        ) : null}
      </div>
      <div className="InitiativeFilters_filters-list">
        {initiative_winner_filter.map((item, index) => (
          <FilterItem
            sectionName="Casos de éxito"
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterWinner}
          />
        ))}
        {initiative_population_filter.map((item, index) => (
          <FilterItem
            sectionName="Poblaciones"
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterPopulation}
          />
        ))}
        {initiative_stretches_filter.map((item, index) => (
          <FilterItem
            sectionName="Temática"
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterStretch}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ initiativeReducer, defaultReducer }) => {
  return {
    initiativeReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  initiativeSearch,
  initiativeFilter,
  initiativeRemoveFilterWinner,
  initiativeAddFilterStretch,
  initiativeAddFilterPopulation,
  initiativeRemoveFilterStretch,
  initiativeRemoveFilterPopulation,
  initiativeChangeWinnerFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitiativeNewFilters);
