export const getRoute = (path) => {
  path = path.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  path = path.replace(/[^a-zA-Z0-9 ]/g, "");
  path = path.toLowerCase().replaceAll(" ", "-");
  path = path.toLowerCase().replaceAll("--", "-");

  return path;
};

export const getLabelRoute = (texts, label) => {
  const end_link = texts.find((item) => item.label === label);
  let end_route = !end_link ? "" : end_link.value;

  return {
    name: end_route,
    slug: getRoute(end_route),
  };
};
