import React, { Component } from "react";
import "./css/SectionSlider.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

class SectionSlider extends Component {
  viewItem = (slide) => {
    let imageMobile = slide.image_mobile ? slide.image_mobile : slide.image;
    return (
      <div key={slide.id} className="slider_slide">
        <div className="slider_overlay">
          <div className="slider_content">
            {slide.title && <div className="slider_title">{slide.title}</div>}
            {slide.link && slide.target ? (
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                VER MÁS
              </a>
            ) : (
              slide.link && <Link to={slide.link}>VER MÁS</Link>
            )}
          </div>
        </div>
        <picture>
          <source media="(max-width: 650px)" srcSet={imageMobile} />
          <img src={slide.image} alt={slide.title} />
        </picture>
      </div>
    );
  };
  render() {
    const { slides } = this.props;
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="slider_section">
        {slides.length > 1 ? (
          <Slider {...settings} ref={(c) => (this.slider = c)}>
            {slides.map((slide) => {
              return this.viewItem(slide);
            })}
          </Slider>
        ) : (
          <>{this.viewItem(slides[0])}</>
        )}
      </div>
    );
  }
}

export default SectionSlider;
