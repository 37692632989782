import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getLabelRoute } from "../utils/GetRoute";

function TerritorySubTabs(props) {
  const {
    territoryReducer: { territory_tab_selected, territory_tab_selected_items },
    defaultReducer: { texts },
    match: {
      params: { tab_item },
    },
    history,
  } = props;
  const territoryLink = getLabelRoute(texts, "menu_2").slug;

  return (
    <>
      <select
        className="form-control territoryInfoPage_submenu-mobile"
        value={tab_item}
        onChange={(input) =>
          history.push(
            `/${territoryLink}/pestana/${territory_tab_selected.slug}/contenido/${input.target.value}`
          )
        }
      >
        {territory_tab_selected_items.map((item) => (
          <option value={item.slug} key={item.id}>
            {item.title}
          </option>
        ))}
      </select>
      <div className="territoryInfoPage_submenu">
        {territory_tab_selected_items.map((item, index) => {
          let active = item.slug === tab_item ? "active" : "";
          if (!tab_item) {
            active = index === 0 ? "active" : "";
          }
          return (
            <Link
              to={`/${territoryLink}/pestana/${territory_tab_selected.slug}/contenido/${item.slug}`}
              key={item.id}
              style={{
                backgroundColor: item.background,
                color: item.color,
                width: `calc(100% / ${territory_tab_selected_items.length})`,
              }}
              className={active}
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    </>
  );
}

const mapStateToProps = ({ territoryReducer, defaultReducer }) => {
  return {
    territoryReducer,
    defaultReducer,
  };
};

export default withRouter(connect(mapStateToProps, null)(TerritorySubTabs));
