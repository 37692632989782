import { LOADING_INITIAL } from "../types/loadingReducer";
import {
  DEFAULT_CONTENTS,
  DEFAULT_EMAIL_NEWSLETTER,
  DEFAULT_NEWSLETTER_MESSAGE,
  DEFAULT_NEWSLETTER_STATUS,
  DEFAULT_SEARCH,
  DEFAULT_SEARCH_CLEAR,
  DEFAULT_SEARCH_STATUS,
  DEFAULT_SEARCH_DATA,
  DEFAULT_MENU,
  DEFAULT_DOCUMENTS_LOADING,
  DEFAULT_DATA_DOCUMENTS,
} from "../types/defaultTypes";
import { DOCS_DATA, DOCS_KEYWORDS } from "../types/docsTypes";

// import { TOOLS_DATA } from "../types/toolsTypes";
// const { tools_items_per_page } = getState().toolsReducer;

import Api from "../Api";
import { docsViewDetail } from "./docsActions";
import { setKeywords } from "../../utils/Keywords";

export const initialSite = () => (dispatch, getState) => {
  document.body.style.overflow = "hidden";
  Api.callMethod("initial-site", "GET", null, function (res) {
    if (res.error.code === 0) {
      const { data } = res;

      dispatch({
        type: DEFAULT_CONTENTS,
        payload: data,
      });

      dispatch({
        type: LOADING_INITIAL,
        payload: "hidden",
      });

      document.body.style.overflow = "";
    } else {
      window.location.reload();
    }
  });
};

export const getDocumentsData =
  (documentId = null) =>
  (dispatch, getState) => {
    const { cities } = getState().defaultReducer;
    const { docs_items_per_page } = getState().docsReducer;

    Api.callMethod("documents-data", "GET", null, function (res) {
      if (res.error.code === 0) {
        const {
          data,
          data: {
            documents,
            filter_population,
            filter_tools,
            filter_sections,
            filter_components,
            filter_drivers,
            filter_action_levels,
            authors,
          },
        } = res;
        let documentCities = cities.filter((item) => item.is_document === 1);
        const population = setKeywords(filter_population);
        const sections = setKeywords(filter_sections);
        const components = setKeywords(filter_components);
        const drivers = setKeywords(filter_drivers);
        const action_levels = setKeywords(filter_action_levels);
        const tools = setKeywords(filter_tools);
        const authorList = setKeywords(authors);
        documentCities = setKeywords(documentCities);

        const keywords = [
          ...population,
          ...sections,
          ...components,
          ...drivers,
          ...action_levels,
          ...tools,
          ...authorList,
          ...documentCities,
        ];

        let data_docs = {
          docs: documents,
          docs_view: documents.slice(0, docs_items_per_page),
        };

        dispatch({
          type: DEFAULT_DATA_DOCUMENTS,
          payload: data,
        });

        dispatch({
          type: DOCS_DATA,
          payload: data_docs,
        });

        dispatch({
          type: DOCS_KEYWORDS,
          payload: keywords,
        });

        dispatch({
          type: DEFAULT_DOCUMENTS_LOADING,
          payload: false,
        });

        if (documentId) {
          dispatch(docsViewDetail(true, documentId));
        }
      }
    });
  };

export const changeEmailNewletter = (value) => (dispatch) => {
  dispatch({
    type: DEFAULT_EMAIL_NEWSLETTER,
    payload: value,
  });
};

export const sendEmailNewsletter = () => (dispatch, getState) => {
  const { email_newsletter } = getState().defaultReducer;
  let emailExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  dispatch({
    type: DEFAULT_NEWSLETTER_MESSAGE,
    payload: "",
  });
  if (email_newsletter.length === 0) {
    dispatch({
      type: DEFAULT_NEWSLETTER_MESSAGE,
      payload: "Debes escribir un email.",
    });
    return null;
  }
  if (!emailExp.test(email_newsletter)) {
    dispatch({
      type: DEFAULT_NEWSLETTER_MESSAGE,
      payload: "Valida que el email este bien escrito.",
    });
    return null;
  }
  let data = {
    email: email_newsletter,
  };
  dispatch({
    type: DEFAULT_NEWSLETTER_STATUS,
    payload: true,
  });
  Api.callMethod("newsletter-inscribe", "POST", data, function (res) {
    if (res.error.code === 0) {
      dispatch({
        type: DEFAULT_NEWSLETTER_MESSAGE,
        payload: "El email ha sido registrado con éxito.",
      });
    } else {
      dispatch({
        type: DEFAULT_NEWSLETTER_MESSAGE,
        payload: "Hubo un error, vuelve a intentarlo más tarde.",
      });
    }
    dispatch({
      type: DEFAULT_NEWSLETTER_STATUS,
      payload: false,
    });
  });
};

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: DEFAULT_SEARCH_CLEAR,
  });
};

export const changeSearch = (value) => (dispatch) => {
  dispatch({
    type: DEFAULT_SEARCH,
    payload: value,
  });
};

export const toSearch = () => (dispatch, getState) => {
  const { tools, documents, ecosystems, search } = getState().defaultReducer;
  dispatch({
    type: DEFAULT_SEARCH_STATUS,
    payload: true,
  });
  let results = [];
  let result_tools = [];
  tools.filter((item) => {
    let value = search.toLowerCase();
    let result_title = item.title.toLowerCase().indexOf(value);
    let result_description = item.description.toLowerCase().indexOf(value);
    if (result_title >= 0 || result_description >= 0) {
      result_tools.push(item);
    }
    return null;
  });
  let result_documents = [];
  documents.filter((item) => {
    let value = search.toLowerCase();
    let result_title = item.title.toLowerCase().indexOf(value);
    let result_description = item.description.toLowerCase().indexOf(value);
    if (result_title >= 0 || result_description >= 0) {
      result_documents.push(item);
    }
    return null;
  });
  let result_ecosystems = [];
  ecosystems.filter((item) => {
    let value = search.toLowerCase();
    let result_iniciativa = item.iniciativa.toLowerCase().indexOf(value);
    if (result_iniciativa >= 0) {
      result_ecosystems.push(item);
    }
    return null;
  });
  if (result_tools.length > 0) {
    results.push({
      title: "HERRAMIENTAS Y TECONOLOGÍA",
      data: result_tools,
      type: 1,
    });
  }
  if (result_documents.length > 0) {
    results.push({
      title: "DOCUMENTOS TÉCNICOS",
      data: result_documents,
      type: 2,
    });
  }
  if (result_ecosystems.length > 0) {
    results.push({
      title: "ECOSISTEMAS DE INICIATIVAS",
      data: result_ecosystems,
      type: 3,
    });
  }
  setTimeout(function () {
    dispatch({
      type: DEFAULT_SEARCH_DATA,
      payload: results,
    });
  }, 2500);
};

export const changeMenu = (status) => (dispatch) => {
  dispatch({
    type: DEFAULT_MENU,
    payload: status,
  });
};
