export const INITIATIVE_LOADING = "INITIATIVE_LOADING";
export const INITIATIVE_ACTORS = "INITIATIVE_ACTORS";
export const INITIATIVE_STRETCHES = "INITIATIVE_STRETCHES";
export const INITIATIVE_COMPONENTS = "INITIATIVE_COMPONENTS";
export const INITIATIVE_LEVELS = "INITIATIVE_LEVELS";
export const INITIATIVE_POPULATIONS = "INITIATIVE_POPULATIONS";
export const INITIATIVE_LIST = "INITIATIVE_LIST";
export const INITIATIVE_VIEW = "INITIATIVE_VIEW";
export const INITIATIVE_ACTORS_FILTER = "INITIATIVE_ACTORS_FILTER";
export const INITIATIVE_STRETCHES_FILTER = "INITIATIVE_STRETCHES_FILTER";
export const INITIATIVE_COMPONENT_FILTER = "INITIATIVE_COMPONENT_FILTER";
export const INITIATIVE_LEVELS_FILTER = "INITIATIVE_LEVELS_FILTER";
export const INITIATIVE_POPULATION_FILTER = "INITIATIVE_POPULATION_FILTER";
export const INITIATIVE_CITIES_FILTER = "INITIATIVE_CITIES_FILTER";
export const INITIATIVE_TOTAL = "INITIATIVE_TOTAL";
export const INITIATIVE_INDIVIDUAL = "INITIATIVE_INDIVIDUAL";
export const INITIATIVE_SEARCH = "INITIATIVE_SEARCH";
export const INITIATIVE_T1_C1 = "INITIATIVE_T1_C1";
export const INITIATIVE_T1_C2 = "INITIATIVE_T1_C2";
export const INITIATIVE_T1_C3 = "INITIATIVE_T1_C3";
export const INITIATIVE_T1_C4 = "INITIATIVE_T1_C4";
export const INITIATIVE_T2_C1 = "INITIATIVE_T2_C1";
export const INITIATIVE_T2_C2 = "INITIATIVE_T2_C2";
export const INITIATIVE_T2_C3 = "INITIATIVE_T2_C3";
export const INITIATIVE_T2_C4 = "INITIATIVE_T2_C4";
export const INITIATIVE_T3_C1 = "INITIATIVE_T3_C1";
export const INITIATIVE_T3_C2 = "INITIATIVE_T3_C2";
export const INITIATIVE_T3_C3 = "INITIATIVE_T3_C3";
export const INITIATIVE_T3_C4 = "INITIATIVE_T3_C4";
export const INITIATIVE_T4_C1 = "INITIATIVE_T4_C1";
export const INITIATIVE_T4_C2 = "INITIATIVE_T4_C2";
export const INITIATIVE_T4_C3 = "INITIATIVE_T4_C3";
export const INITIATIVE_T4_C4 = "INITIATIVE_T4_C4";
export const INITIATIVE_P1 = "INITIATIVE_P1";
export const INITIATIVE_P2 = "INITIATIVE_P2";
export const INITIATIVE_P3 = "INITIATIVE_P3";
export const INITIATIVE_P4 = "INITIATIVE_P4";
export const INITIATIVE_P5 = "INITIATIVE_P5";
export const INITIATIVE_P6 = "INITIATIVE_P6";
export const INITIATIVE_P7 = "INITIATIVE_P7";
export const INITIATIVE_P8 = "INITIATIVE_P8";
export const INITIATIVE_P9 = "INITIATIVE_P9";
export const INITIATIVE_MODAL = "INITIATIVE_MODAL";
export const INITIATIVE_WINNERS = "INITIATIVE_WINNERS";
export const INITIATIVE_WINNER = "INITIATIVE_WINNER";
export const INITIATIVE_WINNER_FILTER = "INITIATIVE_WINNER_FILTER";
export const INITIATIVE_DETAIL = "INITIATIVE_DETAIL";
export const INITIATIVE_DETAIL_LOADING = "INITIATIVE_DETAIL_LOADING";
export const INITIATIVE_DETAIL_CLEAR = "INITIATIVE_DETAIL_CLEAR";
export const INITIATIVE_KEYWORDS = "INITIATIVE_KEYWORDS";
export const INITIATIVE_KEYWORDS_SHOW = "INITIATIVE_KEYWORDS_SHOW";
