import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import BoxSearch from "../components/BoxSearch";
import "./css/IndexPage.css";
import * as indexActions from "../functionality/actions/indexActions";
import loading from "../images/loading.svg";
import SearchResult from "../components/SearchResult";

const { changeSearch, clearSearch, toSearch, changeMenu } = indexActions;

class SearchPage extends Component {
  componentDidMount() {
    const { changeSearch, toSearch } = this.props;
    window.scrollTo(0, 0);
    if (this.props.match.params.words) {
      changeSearch(this.props.match.params.words);
      toSearch();
    }
  }

  componentWillUnmount() {
    const { clearSearch, changeMenu } = this.props;
    changeMenu("");
    clearSearch();
  }

  componentDidUpdate(prevProps) {
    const { toSearch } = this.props;
    if (
      this.props.match.params.words &&
      prevProps.match.params.words !== this.props.match.params.words
    ) {
      toSearch();
    }
  }

  render() {
    const { changeSearch } = this.props;
    const { search, search_data, search_status } = this.props.defaultReducer;
    return (
      <LayoutDefault>
        <BoxSearch
          type="menu"
          placeholder="BUSCADOR"
          value={search}
          onChange={changeSearch}
          action={true}
        />
        <div className="search-container">
          {search_status && (
            <div className="search-loading">
              <img src={loading} alt="Loading" />
            </div>
          )}
          {!search_status && search_data.length === 0 ? (
            <h3 className="text-center">NO HAY RESULTADOS DE LA BÚSQUEDA</h3>
          ) : (
            !search_status && (
              <div className="row">
                {search_data.map((data, index) => {
                  return <SearchResult data={data} key={index} />;
                })}
              </div>
            )
          )}
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ indexReducer, defaultReducer }) => {
  return {
    indexReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  changeSearch,
  clearSearch,
  toSearch,
  changeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
