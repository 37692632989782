import {
  BLOG_ARTICLE,
  BLOG_ARTICLES,
  BLOG_CATEGORIES,
  BLOG_CATEGORY_LOADING,
  BLOG_LOADING,
  BLOG_TOTAL_PAGES,
} from "../types/blogTypes";

const INITIAL_STATE = {
  blog_category_loading: true,
  blog_categories: [],
  blog_articles: [],
  blog_article: null,
  blog_loading: true,
  blog_total_pages: 0,
};

const blogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BLOG_CATEGORY_LOADING:
      return {
        ...state,
        blog_category_loading: action.payload,
      };
    case BLOG_CATEGORIES:
      return {
        ...state,
        blog_categories: action.payload,
      };
    case BLOG_ARTICLES:
      return {
        ...state,
        blog_articles: action.payload,
      };
    case BLOG_ARTICLE:
      return {
        ...state,
        blog_article: action.payload,
      };
    case BLOG_LOADING:
      return {
        ...state,
        blog_loading: action.payload,
      };
    case BLOG_TOTAL_PAGES:
      return {
        ...state,
        blog_total_pages: action.payload,
      };
    default:
      return { ...state };
  }
};

export default blogReducer;
