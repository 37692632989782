import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { getCategories } from "../../functionality/actions/blogActions";
import { getLabelRoute } from "../../utils/GetRoute";
import BlogLayout from "./BlogLayout";
import loading from "../../images/loading.svg";

function ArticleLayout(props) {
  const {
    children,
    defaultReducer: { texts },
    blogReducer: { blog_categories },
    getCategories,
  } = props;
  const [show, setShow] = useState(false);
  const menu6 = getLabelRoute(texts, "menu_6");

  useEffect(() => {
    if (!blog_categories.length) {
      getCategories();
    }
  }, [blog_categories, getCategories]);

  return (
    <BlogLayout>
      <div className="Blog_container row">
        <section className="Blog_articles col-md-9">{children}</section>
        <section className="Blog_categories col-md-3">
          <div className="Blog_categories-container">
            <div className="Blog_categories-header">
              <h2>CATEGORÍAS</h2>
              <button
                className={`${show ? "show" : ""}`}
                onClick={() => setShow(!show)}
              >
                <i className="fas fa-chevron-up"></i>
              </button>
            </div>
            <div className={`Blog_categories-content ${show ? "show" : ""}`}>
              {blog_categories.length ? (
                blog_categories.map((category) => (
                  <NavLink
                    exact
                    to={`/${menu6.slug}/momentos/categoria/${category.slug}`}
                    key={category.id}
                    activeClassName="active"
                  >
                    {category.name}
                  </NavLink>
                ))
              ) : (
                <div className="Blog_loading">
                  <img src={loading} alt="loading" />
                  Cargando categorías...
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </BlogLayout>
  );
}

const mapStateToProps = ({ blogReducer, defaultReducer }) => {
  return {
    blogReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleLayout);
