import React from "react";
import { connect } from "react-redux";
import "./css/InitiativeList.css";
import InitiativeButtonExcel from "./InitiativeButtonExcel";
import List from "./Initiative/List";

function InitiativeList(props) {
  const {
    initiativeReducer: { initiative_view },
  } = props;

  return (
    <div className="InitiativeList_content">
      <List initiatives={initiative_view} />
      <div className="mt-4">
        <InitiativeButtonExcel />
      </div>
    </div>
  );
}

const mapStateToProps = ({ initiativeReducer }) => {
  return {
    initiativeReducer,
  };
};

export default connect(mapStateToProps, null)(InitiativeList);
