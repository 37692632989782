export const setKeywords = (data) => {
  let keywords = [];

  data.map((item) => {
    let isAdded = keywords.find((key) => key.name === item.name);
    if (!isAdded) {
      keywords.push({
        searchable: item.name.toLowerCase(),
        name: item.name,
      });
    }
    return null;
  });

  keywords = keywords.filter((item) => item);

  return keywords;
};
