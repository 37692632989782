import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function BlogPagination(props) {
  const { url, currentPage, totalPages } = props;
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pages = [];
    let baseUrl = url.split("/pagina")[0];

    for (let i = 1; i <= totalPages; i++) {
      pages.push({
        url: `${baseUrl}/pagina/${i}`,
        number: i,
        active: i === currentPage ? "active" : "",
      });
    }

    setPages(pages);
  }, [url, currentPage, totalPages, setPages]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="Blog_pagination">
      {pages.map((page) => (
        <Link key={page.number} to={page.url} className={page.active}>
          {page.number}
        </Link>
      ))}
    </div>
  );
}

export default BlogPagination;
