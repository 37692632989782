import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getLabelRoute } from "../utils/GetRoute";

function ArticleResume(props) {
  const {
    article,
    defaultReducer: { texts },
  } = props;
  const menu6 = getLabelRoute(texts, "menu_6");

  return (
    <article className="Blog_article-resume">
      <div className="Blog_article-image">
        {article.cover && <img src={article.cover} alt={article.title} />}
      </div>
      <div className="Blog_article-content">
        <div>
          <h2>{article.title}</h2>
          <p className="Blog_article-date">Escrito el: {article.created_at}</p>
          <div dangerouslySetInnerHTML={{ __html: article.summary }} />
        </div>
        <Link to={`/${menu6.slug}/momentos/articulo/${article.slug}`}>
          Leer más
        </Link>
      </div>
    </article>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(mapStateToProps, null)(ArticleResume);
