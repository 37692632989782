import React, { Fragment } from "react";
import { connect } from "react-redux";
import "./css/Footer.css";
import * as indexActions from "../functionality/actions/indexActions";
// import footerImages from "../images/logo-footer.png";
import logoFundacion from "../images/logo-fundacion.png";
import Logotypes from "./Logotypes";

const { changeEmailNewletter, sendEmailNewsletter } = indexActions;

function Footer(props) {
  const { social_networks, other_contents, images, logotypes } =
    props.defaultReducer;
  let date = new Date();
  let year = date.getFullYear();
  let footer = other_contents.filter(
    (item) => item.label === "quines_somos_footer"
  );
  let contact = other_contents.filter((item) => item.label === "contacto");
  let imagesFooter = images.filter((item) => item.label.indexOf("footer") >= 0);
  let networks = social_networks.filter((item) => item.in_footer);
  // let image1 =
  //   !imagesFooter.length || !imagesFooter[0].image
  //     ? footerImages
  //     : imagesFooter[0].image;
  let image2 =
    !imagesFooter.length || !imagesFooter[1].image
      ? logoFundacion
      : imagesFooter[1].image;

  return (
    <Fragment>
      <footer>
        <div className="footer">
          <div className="content-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6 footer-col">
                  <div dangerouslySetInnerHTML={{ __html: footer[0].value }} />
                  <Logotypes logotypes={logotypes} />
                </div>
                {/* <div className="col-md-4 footer-col"></div> */}
                <div className="col-md-6 footer-col">
                  <h4>{contact[0].title}</h4>
                  <div
                    className="footer_contact"
                    dangerouslySetInnerHTML={{ __html: contact[0].value }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 footer-col">
                <p>Fundación Corona © {year} - Todos los derechos reservados</p>
              </div>
              <div className="col-md-4 footer-col">
                {networks.map((company) => (
                  <div key={company.id}>
                    {networks.length > 1 && (
                      <h5 className="footer-social-title">{company.name}</h5>
                    )}
                    <div className="footer-social">
                      {company.social_networks.map((item) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={item.id}
                        >
                          <i className={item.icon}></i>
                        </a>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-4 footer-col">
                <img
                  src={image2}
                  alt="Logo Fundación Corona"
                  className="footer_logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          Made with ❤ by{" "}
          <a
            href="https://believe-global.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Believe!
          </a>{" "}
          © Copyrigts {year}
        </div>
      </footer>
    </Fragment>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

const mapDispatchToProps = {
  changeEmailNewletter,
  sendEmailNewsletter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
