import React, { Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import "./css/Header.css";
import logo from "../images/logo.png";
import { connect } from "react-redux";
import { changeMenu } from "../functionality/actions/indexActions";
import {
  userFormType,
  userFormModal,
  userLogout,
} from "../functionality/actions/userActions";
import { getLabelRoute } from "../utils/GetRoute";

function Header(props) {
  const {
    defaultReducer: { menu_visible, texts, images },
    userReducer: { user },
    changeMenu,
    userFormType,
    userFormModal,
    userLogout,
  } = props;
  const slogan = texts.find((item) => item.label === "slogan");
  const menu1 = getLabelRoute(texts, "menu_1");
  const menu2 = getLabelRoute(texts, "menu_2");
  const menu4 = getLabelRoute(texts, "menu_4");
  const menu5 = getLabelRoute(texts, "menu_5");
  const menu6 = getLabelRoute(texts, "menu_6");
  const menuConecta = getLabelRoute(texts, "menu_conecta");
  let img_logo = images.find((item) => item.label === "logo");
  img_logo = !img_logo || !img_logo.image ? logo : img_logo.image;

  return (
    <Fragment>
      <header>
        <div className="Header_session">
          {user ? (
            <>
              <button
                onClick={() => {
                  userFormType("profile");
                  userFormModal("visible");
                }}
              >
                Mis datos
              </button>
              /<button onClick={userLogout}>Cerrar sesión</button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  userFormType("login");
                  userFormModal("visible");
                }}
              >
                Iniciar Sesión
              </button>
              /
              <button
                onClick={() => {
                  userFormType("register");
                  userFormModal("visible");
                }}
              >
                Registrarme
              </button>
            </>
          )}
        </div>
        <Link to="/">
          <img src={img_logo} alt="logo" className="header-logo" />
          <p>{slogan.value}</p>
        </Link>
      </header>
      <div className="header-box-menu">
        <div className="header-line" />
        <button
          className="menu-responsive"
          onClick={() => changeMenu("visible")}
        >
          <span className="icon-menu" />
          MENÚ
        </button>
        <div className={`header-menu ${menu_visible}`}>
          <button onClick={() => changeMenu("")} className="close-menu">
            <span className="icon-plus" />
          </button>
          <NavLink
            exact
            to={`/`}
            className="header-about"
            activeClassName="header-about-active"
          >
            {menu1.name}
            <div />
          </NavLink>
          <NavLink
            to={`/${menu6.slug}`}
            className="header-news"
            activeClassName="header-news-active"
          >
            {menu6.name}
            <div />
          </NavLink>
          <NavLink
            exact
            to={`/${menu2.slug}`}
            className="header-territory"
            activeClassName="header-territory-active"
          >
            {menu2.name}
            <div />
          </NavLink>
          <NavLink
            exact
            to={`/${menu5.slug}`}
            className="header-document"
            activeClassName="header-document-active"
          >
            {menu5.name}
            <div />
          </NavLink>
          <NavLink
            exact
            to={`/${menu4.slug}`}
            className="header-ecosystem"
            activeClassName="header-ecosystem-active"
          >
            {menu4.name}
            <div />
          </NavLink>
          <NavLink
            exact
            to={`/${menuConecta.slug}`}
            className="header-contact"
            activeClassName="header-contact-active"
          >
            {menuConecta.name}
            <div />
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ defaultReducer, userReducer }) => {
  return {
    defaultReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  changeMenu,
  userFormType,
  userFormModal,
  userLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
