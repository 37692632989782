import {
  INITIATIVE_LOADING,
  INITIATIVE_ACTORS,
  INITIATIVE_STRETCHES,
  INITIATIVE_COMPONENTS,
  INITIATIVE_LEVELS,
  INITIATIVE_POPULATIONS,
  INITIATIVE_LIST,
  INITIATIVE_VIEW,
  INITIATIVE_ACTORS_FILTER,
  INITIATIVE_STRETCHES_FILTER,
  INITIATIVE_COMPONENT_FILTER,
  INITIATIVE_LEVELS_FILTER,
  INITIATIVE_POPULATION_FILTER,
  INITIATIVE_CITIES_FILTER,
  INITIATIVE_TOTAL,
  INITIATIVE_INDIVIDUAL,
  INITIATIVE_SEARCH,
  INITIATIVE_T1_C1,
  INITIATIVE_T1_C2,
  INITIATIVE_T1_C3,
  INITIATIVE_T1_C4,
  INITIATIVE_T2_C1,
  INITIATIVE_T2_C2,
  INITIATIVE_T2_C3,
  INITIATIVE_T2_C4,
  INITIATIVE_T3_C1,
  INITIATIVE_T3_C2,
  INITIATIVE_T3_C3,
  INITIATIVE_T3_C4,
  INITIATIVE_T4_C1,
  INITIATIVE_T4_C2,
  INITIATIVE_T4_C3,
  INITIATIVE_T4_C4,
  INITIATIVE_P1,
  INITIATIVE_P2,
  INITIATIVE_P3,
  INITIATIVE_P4,
  INITIATIVE_P5,
  INITIATIVE_P6,
  INITIATIVE_P7,
  INITIATIVE_P8,
  INITIATIVE_P9,
  INITIATIVE_MODAL,
  INITIATIVE_WINNERS,
  INITIATIVE_WINNER,
  INITIATIVE_WINNER_FILTER,
  INITIATIVE_DETAIL,
  INITIATIVE_DETAIL_LOADING,
  INITIATIVE_DETAIL_CLEAR,
  INITIATIVE_KEYWORDS,
  INITIATIVE_KEYWORDS_SHOW,
} from "../types/initiativeTypes";

const INTIAL_STATE = {
  initiative_loading: true,
  initiative_actors: [],
  initiative_stretches: [],
  initiative_components: [],
  initiative_levels: [],
  initiative_populations: [],
  initiative_list: [],
  initiative_view: [],
  initiative_actors_filter: [],
  initiative_stretches_filter: [],
  initiative_component_filter: [],
  initiative_levels_filter: [],
  initiative_population_filter: [],
  initiative_cities_filter: [],
  initiative_total: 0,
  initiative_individual: 0,
  initiative_search: "",
  initiative_t1_c1: 0,
  initiative_t1_c2: 0,
  initiative_t1_c3: 0,
  initiative_t1_c4: 0,
  initiative_t2_c1: 0,
  initiative_t2_c2: 0,
  initiative_t2_c3: 0,
  initiative_t2_c4: 0,
  initiative_t3_c1: 0,
  initiative_t3_c2: 0,
  initiative_t3_c3: 0,
  initiative_t3_c4: 0,
  initiative_t4_c1: 0,
  initiative_t4_c2: 0,
  initiative_t4_c3: 0,
  initiative_t4_c4: 0,
  initiative_p1: 0,
  initiative_p2: 0,
  initiative_p3: 0,
  initiative_p4: 0,
  initiative_p5: 0,
  initiative_p6: 0,
  initiative_p7: 0,
  initiative_p8: 0,
  initiative_p9: 0,
  initiative_modal: "",
  initiative_winners: [],
  initiative_winner_filter: [],
  initiative_winner: null,
  initiative_detail: null,
  initiative_detail_loading: true,
  initiative_keywords: [],
  initiative_keywords_show: [],
};

const initiativeReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case INITIATIVE_LOADING:
      return {
        ...state,
        initiative_loading: action.payload,
      };
    case INITIATIVE_ACTORS:
      return {
        ...state,
        initiative_actors: action.payload,
      };
    case INITIATIVE_STRETCHES:
      return {
        ...state,
        initiative_stretches: action.payload,
      };
    case INITIATIVE_COMPONENTS:
      return {
        ...state,
        initiative_components: action.payload,
      };
    case INITIATIVE_LEVELS:
      return {
        ...state,
        initiative_levels: action.payload,
      };
    case INITIATIVE_POPULATIONS:
      return {
        ...state,
        initiative_populations: action.payload,
      };
    case INITIATIVE_WINNERS:
      return {
        ...state,
        initiative_winners: action.payload,
      };
    case INITIATIVE_WINNER_FILTER:
      return {
        ...state,
        initiative_winner_filter: action.payload,
      };
    case INITIATIVE_WINNER:
      return {
        ...state,
        initiative_winner: action.payload,
      };
    case INITIATIVE_LIST:
      return {
        ...state,
        initiative_list: action.payload,
      };
    case INITIATIVE_VIEW:
      return {
        ...state,
        initiative_view: action.payload,
      };
    case INITIATIVE_ACTORS_FILTER:
      return {
        ...state,
        initiative_actors_filter: action.payload,
      };
    case INITIATIVE_STRETCHES_FILTER:
      return {
        ...state,
        initiative_stretches_filter: action.payload,
      };
    case INITIATIVE_COMPONENT_FILTER:
      return {
        ...state,
        initiative_component_filter: action.payload,
      };
    case INITIATIVE_LEVELS_FILTER:
      return {
        ...state,
        initiative_levels_filter: action.payload,
      };
    case INITIATIVE_POPULATION_FILTER:
      return {
        ...state,
        initiative_population_filter: action.payload,
      };
    case INITIATIVE_CITIES_FILTER:
      return {
        ...state,
        initiative_cities_filter: action.payload,
      };
    case INITIATIVE_TOTAL:
      return {
        ...state,
        initiative_total: action.payload,
      };
    case INITIATIVE_INDIVIDUAL:
      return {
        ...state,
        initiative_individual: action.payload,
      };
    case INITIATIVE_SEARCH:
      return {
        ...state,
        initiative_search: action.payload,
      };
    case INITIATIVE_T1_C1: {
      return {
        ...state,
        initiative_t1_c1: action.payload,
      };
    }
    case INITIATIVE_T1_C2: {
      return {
        ...state,
        initiative_t1_c2: action.payload,
      };
    }
    case INITIATIVE_T1_C3: {
      return {
        ...state,
        initiative_t1_c3: action.payload,
      };
    }
    case INITIATIVE_T1_C4: {
      return {
        ...state,
        initiative_t1_c4: action.payload,
      };
    }
    case INITIATIVE_T2_C1: {
      return {
        ...state,
        initiative_t2_c1: action.payload,
      };
    }
    case INITIATIVE_T2_C2: {
      return {
        ...state,
        initiative_t2_c2: action.payload,
      };
    }
    case INITIATIVE_T2_C3: {
      return {
        ...state,
        initiative_t2_c3: action.payload,
      };
    }
    case INITIATIVE_T2_C4: {
      return {
        ...state,
        initiative_t2_c4: action.payload,
      };
    }
    case INITIATIVE_T3_C1: {
      return {
        ...state,
        initiative_t3_c1: action.payload,
      };
    }
    case INITIATIVE_T3_C2: {
      return {
        ...state,
        initiative_t3_c2: action.payload,
      };
    }
    case INITIATIVE_T3_C3: {
      return {
        ...state,
        initiative_t3_c3: action.payload,
      };
    }
    case INITIATIVE_T3_C4: {
      return {
        ...state,
        initiative_t3_c4: action.payload,
      };
    }
    case INITIATIVE_T4_C1: {
      return {
        ...state,
        initiative_t4_c1: action.payload,
      };
    }
    case INITIATIVE_T4_C2: {
      return {
        ...state,
        initiative_t4_c2: action.payload,
      };
    }
    case INITIATIVE_T4_C3: {
      return {
        ...state,
        initiative_t4_c3: action.payload,
      };
    }
    case INITIATIVE_T4_C4: {
      return {
        ...state,
        initiative_t4_c4: action.payload,
      };
    }
    case INITIATIVE_P1: {
      return {
        ...state,
        initiative_p1: action.payload,
      };
    }
    case INITIATIVE_P2: {
      return {
        ...state,
        initiative_p2: action.payload,
      };
    }
    case INITIATIVE_P3: {
      return {
        ...state,
        initiative_p3: action.payload,
      };
    }
    case INITIATIVE_P4: {
      return {
        ...state,
        initiative_p4: action.payload,
      };
    }
    case INITIATIVE_P5: {
      return {
        ...state,
        initiative_p5: action.payload,
      };
    }
    case INITIATIVE_P6: {
      return {
        ...state,
        initiative_p6: action.payload,
      };
    }
    case INITIATIVE_P7: {
      return {
        ...state,
        initiative_p7: action.payload,
      };
    }
    case INITIATIVE_P8: {
      return {
        ...state,
        initiative_p8: action.payload,
      };
    }
    case INITIATIVE_P9: {
      return {
        ...state,
        initiative_p9: action.payload,
      };
    }
    case INITIATIVE_MODAL: {
      return {
        ...state,
        initiative_modal: action.payload,
      };
    }
    case INITIATIVE_DETAIL:
      return {
        ...state,
        initiative_detail: action.payload,
      };
    case INITIATIVE_DETAIL_LOADING:
      return {
        ...state,
        initiative_detail_loading: action.payload,
      };
    case INITIATIVE_DETAIL_CLEAR:
      return {
        ...state,
        initiative_detail: null,
        initiative_detail_loading: true,
      };
    case INITIATIVE_KEYWORDS:
      return {
        ...state,
        initiative_keywords: action.payload,
      };
    case INITIATIVE_KEYWORDS_SHOW:
      return {
        ...state,
        initiative_keywords_show: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default initiativeReducer;
