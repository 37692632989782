import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLabelRoute } from "../utils/GetRoute";

function SearchItem(props) {
  const {
    defaultReducer: { texts },
    item,
    type,
  } = props;
  let slug = item.slug ? item.slug : item.id;
  let to_url = type === 1 ? "menu_3" : "menu_5";
  to_url = getLabelRoute(texts, to_url).slug;
  to_url = `/${to_url}/${slug}`;

  return (
    <div className="col-md-4">
      <div className="search-item">
        {type === 1 || type === 2 ? (
          <Fragment>
            <div className="searc-box">
              <img
                src={item.coverpage}
                alt="Coverpage"
                className="search-coverpage"
              />
              {item.title}
            </div>
            <div className="search-item-menu">
              <Link to={to_url}>Ver más</Link>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {item.iniciativa}
            <div className="search-item-menu">
              <a href={to_url} target="_blank" rel="noopener noreferrer">
                Ver link
              </a>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return { defaultReducer };
};

export default connect(mapStateToProps)(SearchItem);
