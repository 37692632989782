import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BlogLayout from "./layouts/BlogLayout";

function AilPage(props) {
  const {
    defaultReducer: { other_contents },
  } = props;
  const [content, setContent] = useState(null);

  useEffect(() => {
    let content = other_contents.find((item) => item.label === "noticias");
    setContent(content);
  }, [other_contents]);

  return (
    <BlogLayout>
      <div
        className="description-news"
        dangerouslySetInnerHTML={{ __html: content ? content.value : "" }}
      />
    </BlogLayout>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(mapStateToProps, null)(AilPage);
