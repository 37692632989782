import {
  CONTACT_NAME,
  CONTACT_INSTITUTION,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_MESSAGE,
  CONTACT_ERROR,
  CONTACT_CLEAR,
  CONTACT_STATUS,
  CONTACT_SUCCESS,
  CONTACT_TERMS,
} from "../types/contactTypes";

const INITIAL_STATE = {
  contact_name: "",
  contact_institution: "",
  contact_email: "",
  contact_phone: "",
  contact_message: "",
  contact_error: "",
  contact_success: "",
  contact_status: false,
  contact_terms: 0,
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACT_NAME:
      return {
        ...state,
        contact_name: action.payload,
      };
    case CONTACT_INSTITUTION:
      return {
        ...state,
        contact_institution: action.payload,
      };
    case CONTACT_EMAIL:
      return {
        ...state,
        contact_email: action.payload,
      };
    case CONTACT_PHONE:
      return {
        ...state,
        contact_phone: action.payload,
      };
    case CONTACT_MESSAGE:
      return {
        ...state,
        contact_message: action.payload,
      };
    case CONTACT_TERMS:
      return {
        ...state,
        contact_terms: action.payload,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        contact_error: action.payload,
        contact_success: "",
      };
    case CONTACT_CLEAR:
      return {
        ...state,
        contact_name: "",
        contact_institution: "",
        contact_email: "",
        contact_phone: "",
        contact_message: "",
        contact_error: "",
        contact_success: "",
        contact_status: false,
        contact_terms: 0,
      };
    case CONTACT_STATUS:
      return {
        ...state,
        contact_status: action.payload,
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        contact_name: "",
        contact_institution: "",
        contact_email: "",
        contact_phone: "",
        contact_message: "",
        contact_error: "",
        contact_success: action.payload,
        contact_status: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default contactReducer;
