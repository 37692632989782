import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  initiativeGetDetail,
  initiativeDetailClear,
} from "../../functionality/actions/initiativeActions";
import loaded from "../../images/loading.svg";
import "./css/Detail.css";
import InitiativeIcon from "../InitiativeIcon";
import { getLabelRoute } from "../../utils/GetRoute";

class Detail extends Component {
  componentDidMount() {
    const { slug, initiativeGetDetail } = this.props;
    initiativeGetDetail(slug);
    document.body.classList.add("no-scroll");
  }

  componentWillUnmount() {
    const { initiativeDetailClear } = this.props;
    document.body.classList.remove("no-scroll");
    initiativeDetailClear();
  }

  viewItem = (slide) => {
    return (
      <div key={slide.id} className="Detail_slide">
        <img src={slide.image} alt={`Slide ${slide.id}`} />
      </div>
    );
  };

  renderSubtitle = (label, text = false) => {
    const {
      defaultReducer: { ecosystem_titles },
    } = this.props;
    let title = ecosystem_titles.find((item) => item.label === label);

    if (text && title) {
      return <>{title.value}</>;
    }

    if (title) {
      return <div className="Detail_subtitle">{title.value}</div>;
    }

    return null;
  };

  render() {
    const {
      initiativeReducer: {
        initiative_detail: initiative,
        initiative_detail_loading,
      },
      defaultReducer: { texts },
    } = this.props;
    const menu4 = getLabelRoute(texts, "menu_4");

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 300,
      centerMode: true,
      slidesToShow: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            variableWidth: false,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="Detail_modal">
        <div className="Detail_container">
          <Link to={`/${menu4.slug}`} className="Detail_close">
            <i className="far fa-times-circle" />
          </Link>
          <div className="Detail_content">
            {!initiative || initiative_detail_loading ? (
              <div className="Detail_loading">
                <img src={loaded} alt="Loading..." />
              </div>
            ) : (
              <>
                <h1>{initiative.name}</h1>
                {initiative.official_link && (
                  <a
                    href={initiative.official_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="Detail_link"
                  >
                    <span className="icon-link" />
                    {this.renderSubtitle("ecosistema_detalle_link", true)}
                  </a>
                )}
                <div className="InitiativeList_icons Detail_icons">
                  {initiative.stretches.data.map((stretch) => (
                    <InitiativeIcon
                      position="left"
                      key={stretch.id}
                      label={stretch.label}
                      name={stretch.name}
                      icon={stretch.icon}
                    />
                  ))}
                  {initiative.components.data.map((component) => (
                    <InitiativeIcon
                      position="left"
                      key={component.id}
                      label={component.label}
                      name={component.name}
                      icon={component.icon}
                    />
                  ))}
                  {initiative.levels.data.map((level) => (
                    <InitiativeIcon
                      position="left"
                      key={level.id}
                      label={level.label}
                      name={level.name}
                      icon={level.icon}
                    />
                  ))}
                </div>
                <div className="InitiativeList_actors">
                  {initiative.actors.data.map((actor) => (
                    <span key={actor.id}>{actor.name}</span>
                  ))}
                </div>
                {initiative.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: initiative.description }}
                  />
                )}
                {initiative.video && (
                  <>
                    {this.renderSubtitle("ecosistema_detalle_video")}
                    <div
                      className="Detail_video"
                      dangerouslySetInnerHTML={{ __html: initiative.video }}
                    />
                  </>
                )}
                {initiative.galleries.length ? (
                  <>
                    {this.renderSubtitle("ecosistema_detalle_galeria")}
                    <Slider {...settings} ref={(c) => (this.slider = c)}>
                      {initiative.galleries.map((slide) => {
                        return this.viewItem(slide);
                      })}
                    </Slider>
                  </>
                ) : null}
                {initiative.files.length ? (
                  <>
                    {this.renderSubtitle("ecosistema_detalle_archivos")}
                    <div className="Detail_files">
                      {initiative.files.map((file) => (
                        <a
                          href={file.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={file.id}
                        >
                          <i className={file.icon} />
                          {file.name}
                        </a>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ initiativeReducer, defaultReducer }) => {
  return { initiativeReducer, defaultReducer };
};

const mapDispatchToProps = {
  initiativeGetDetail,
  initiativeDetailClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
