import React from "react";
import { connect } from "react-redux";
import ButtonDownload from "./ButtonDownlaod";
import ModalDownload from "./ModalDownload";
import "./css/DocDetail.css";
import * as modalActions from "../functionality/actions/modalActions";

const { modalChangeStatus } = modalActions;

function DocDetail(props) {
  const { modalChangeStatus, file, section } = props;
  let nameButton =
    file[0].is_private === 1 ? "SOLICITAR DOCUMENTO" : "DESCARGAR";

  return (
    <div className="doc-detail-content">
      <ModalDownload
        button_name={nameButton}
        title={file[0].title}
        section={props.section}
        file={file[0].file}
        survey={file[0].survey}
      />
      <div className="row">
        <div className="col-md-3">
          <img src={file[0].coverpage} alt="coverpage" />
        </div>
        <div className="col-md-9">
          <h1>{file[0].title}</h1>
          {file[0].subtitle && <h4>{file[0].subtitle}</h4>}
          <div
            className="doc-detail-description"
            dangerouslySetInnerHTML={{
              __html: file[0].description.replace("\n", "<br/>"),
            }}
          />
          {section === "DOCUMENTOS TÉCNICOS" && (
            <div className="doc-detail-complement">
              {file[0].authors_active.length ? (
                <div className="doc-detail-row">
                  <div className="doc-detail-label">Autor(es):</div>
                  <div className="doc-detail-authors">
                    {file[0].authors_active.map((author) => (
                      <span key={author.id}>{author.name}</span>
                    ))}
                  </div>
                </div>
              ) : null}
              {file[0].year && (
                <div className="doc-detail-row">
                  <div className="doc-detail-label">Año:</div>
                  <div>{file[0].year}</div>
                </div>
              )}
              {file[0].source && (
                <div className="doc-detail-row">
                  <div className="doc-detail-label">Fuente:</div>
                  <div>{file[0].source}</div>
                </div>
              )}
            </div>
          )}

          {file[0].link && file[0].link.length > 0 ? (
            <a
              href={file[0].link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn-go-link"
            >
              Ver Archivo
            </a>
          ) : (
            <ButtonDownload
              name={nameButton}
              action={() =>
                modalChangeStatus(
                  "visible",
                  file[0].is_private,
                  file[0].file,
                  file[0].survey
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ modalReducer }) => {
  return {
    modalReducer,
  };
};

const mapDispatchToProps = {
  modalChangeStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocDetail);
