import React from "react";

function Logotypes(props) {
  const { logotypes } = props;

  return (
    <div className="footer-logotypes">
      {logotypes.map((logotype) => {
        if (logotype.link) {
          return (
            <a
              key={logotype.id}
              href={logotype.link}
              target="_blank"
              rel="noopener noreferrer"
              className="logotype"
            >
              <img src={logotype.image} alt={logotype.title} />
            </a>
          );
        }
        return (
          <div key={logotype.id} className="logotype">
            <img src={logotype.image} alt={logotype.title} />
          </div>
        );
      })}
    </div>
  );
}

export default Logotypes;
