import Api from "../Api";
import {
  BLOG_ARTICLE,
  BLOG_ARTICLES,
  BLOG_CATEGORIES,
  BLOG_LOADING,
  BLOG_TOTAL_PAGES,
} from "../types/blogTypes";

export const changeBlogLoading = (payload) => {
  return {
    type: BLOG_LOADING,
    payload,
  };
};

export const getCategories = () => (dispatch) => {
  Api.callMethod("blog-categories", "get", null, (response) => {
    const { data } = response;

    dispatch({
      type: BLOG_CATEGORIES,
      payload: data,
    });
  });
};

export const setArticles = (route) => (dispatch) => {
  Api.callMethod(route, "get", null, (response) => {
    dispatch({
      type: BLOG_ARTICLES,
      payload: response.data,
    });

    dispatch({
      type: BLOG_TOTAL_PAGES,
      payload: response.meta.last_page,
    });

    dispatch(changeBlogLoading(false));
  });
};

export const getArticles = (page) => (dispatch) => {
  dispatch(changeBlogLoading(true));

  dispatch(setArticles(`blogs?page=${page}`));
};

export const getArticlesByCategory =
  (categorySlug, page) => (dispatch, getState) => {
    dispatch(changeBlogLoading(true));

    const { blog_categories } = getState().blogReducer;
    let category = blog_categories.find(
      (category) => category.slug === categorySlug
    );

    if (!category) {
      window.location.href = "/";
      return;
    }

    let route = `blog-categories/${category.id}/articles?page=${page}`;
    dispatch(setArticles(route));
  };

export const getArticle = (slug) => (dispatch) => {
  dispatch(changeBlogLoading(true));

  Api.callMethod(
    `blogs/${slug}`,
    "get",
    null,
    (response) => {
      dispatch({
        type: BLOG_ARTICLE,
        payload: response.data,
      });

      dispatch(changeBlogLoading(false));
    },
    () => {
      window.location.href = "/";
    }
  );
};
