import { combineReducers } from "redux";
import indexReducer from "./indexReducer";
import aboutReducer from "./aboutReducer";
import territoryReducer from "./territoryReducer";
import defaultReducer from "./defaultReducer";
import toolsReducer from "./toolsReducer";
import ecosystemsReducer from "./ecosystemsReducer";
import docsReducer from "./docsReducer";
import modalReducer from "./modalReducer";
import loadingReducer from "./loadingReducer";
import contactReducer from "./contactReducer";
import newsReducer from "./newsReducer";
import userReducer from "./userReducer";
import initiativeReducer from "./initiativeReducer";
import blogReducer from "./blogReducer";

export default combineReducers({
  indexReducer,
  aboutReducer,
  territoryReducer,
  defaultReducer,
  toolsReducer,
  ecosystemsReducer,
  docsReducer,
  modalReducer,
  loadingReducer,
  contactReducer,
  newsReducer,
  userReducer,
  initiativeReducer,
  blogReducer,
});
