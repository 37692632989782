export const CONTACT_NAME = "CONTACT_NAME";
export const CONTACT_INSTITUTION = "CONTACT_INSTITUTION";
export const CONTACT_EMAIL = "CONTACT_EMAIL";
export const CONTACT_PHONE = "CONTACT_PHONE";
export const CONTACT_MESSAGE = "CONTACT_MESSAGE";
export const CONTACT_CLEAR = "CONTACT_CLEAR";
export const CONTACT_STATUS = "CONTACT_STATUS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_TERMS = "CONTACT_TERMS";
