import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import image from "../images/bg-documents.jpg";
import DocDetail from "../components/DocDetail";
import BoxSearch from "../components/BoxSearch";
import Pagination from "../components/Pagination";
import SectionSlider from "../components/SectionSlider";
import ButtonGraph from "../components/ButtonGraph";
import DocsList from "../components/DocsList";
import { getLabelRoute } from "../utils/GetRoute";
import {
  docsChangePage,
  docsViewDetail,
  docsSearch,
  docsFilters,
  docsClear,
  docsViewGraph,
  changeDocsListType,
} from "../functionality/actions/docsActions";
import {
  changeMenu,
  getDocumentsData,
} from "../functionality/actions/indexActions";

class DocumentsPage extends Component {
  componentDidMount() {
    const {
      getDocumentsData,
      defaultReducer: { documents_loading },
      match: {
        params: { id },
      },
      docsViewDetail,
    } = this.props;
    window.scrollTo(0, 0);
    let docuemntId = id ?? null;

    if (documents_loading) {
      getDocumentsData(docuemntId);
    } else if (docuemntId) {
      docsViewDetail(true, docuemntId);
    }

    if (this.props.match.params.id) {
    }
  }

  componentDidUpdate(prevProps) {
    const { docsViewDetail } = this.props;
    if (!this.props.match.params.id && prevProps.match.params.id) {
      window.scrollTo(0, 0);
      docsViewDetail(false, "");
    }
    if (
      this.props.match.params.id &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      docsViewDetail(true, this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    const { docsClear, changeMenu, docsViewGraph } = this.props;
    changeMenu("");
    docsClear();
    docsViewGraph(true);
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Documentos Técnicos" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  render() {
    const {
      docsReducer: {
        docs_list,
        docs_current_page,
        docs_items_per_page,
        docs_view_detail,
        docs_detail,
        docs_search,
        docs_view_graph,
        docs_list_view,
        docs_list_type,
        docs_keywords_show,
      },
      defaultReducer: { other_contents, sliders, texts },
      docsChangePage,
      docsSearch,
      docsViewGraph,
    } = this.props;
    let link = getLabelRoute(texts, "menu_5").slug;
    let document = other_contents.filter(
      (item) => item.label === "documentos_tecnicos"
    );
    let slider = sliders.filter((item) => item.name === "documentos_tecnicos");
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("documentos_grafico") >= 0
    );

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div
                  className="title-section"
                  dangerouslySetInnerHTML={{
                    __html: document[0].title.replace(" ", "</br>"),
                  }}
                />
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-green"
              dangerouslySetInnerHTML={{ __html: document[0].value }}
            />
          </div>
        </div>
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" && (
          <div className="">
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#8C8536"
                  name={graphic[0].value}
                  action={() => docsViewGraph(!docs_view_graph)}
                  status={docs_view_graph}
                />
                {docs_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        )}
        <div className="section-content" id="docs-container">
          <BoxSearch
            placeholder="BUSCADOR PALABRAS CLAVES"
            value={docs_search}
            onChange={docsSearch}
            keywords={docs_keywords_show}
          />
          {docs_search.length >= 4 && (
            <div className="tools-search">
              Resultados de la busqueda: {docs_search}
            </div>
          )}
          {docs_list.length > 0 ? (
            <Fragment>
              <DocsList
                data={docs_list_view}
                listType={docs_list_type}
                link={`/${link}`}
              />
              <Pagination
                all_data={docs_list}
                items_per_page={docs_items_per_page}
                current_page={docs_current_page}
                change_page={docsChangePage}
              />
            </Fragment>
          ) : (
            <div className="section-content">
              <h3 className="text-center">NO HAY ARCHIVOS DISPONIBLES</h3>
            </div>
          )}
        </div>
        <div id="doc-detail">
          {docs_view_detail && docs_detail.length > 0 ? (
            <DocDetail file={docs_detail} section="DOCUMENTOS TÉCNICOS" />
          ) : (
            docs_view_detail && (
              <h3 className="text-center">EL ARCHIVO NO ESTÁ DISPONIBLE</h3>
            )
          )}
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ docsReducer, defaultReducer }) => {
  return {
    docsReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  docsChangePage,
  docsViewDetail,
  docsSearch,
  docsFilters,
  docsClear,
  changeMenu,
  docsViewGraph,
  changeDocsListType,
  getDocumentsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
