import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { initialSite } from "./functionality/actions/indexActions";
import { userSessionStatus } from "./functionality/actions/userActions";
import { getLabelRoute } from "./utils/GetRoute";
import GA from "./utils/GoogleAnalytics";
import AboutPage from "./pages/AboutPage";
import TerritoryPage from "./pages/TerritoryPage";
import ToolsPage from "./pages/ToolsPage";
import EcosystemsPage from "./pages/EcosystemsPage";
import DocumentsPage from "./pages/DocumentsPage";
import ContactPage from "./pages/ContactPage";
import SearchPage from "./pages/SearchPage";
// import NewsPage from "./pages/NewsPage";
import ModalLoading from "./components/ModalLoading";
import NewsDetailPage from "./pages/NewsDetailPage";
import ErrorPage from "./pages/ErrorPage";
import TerritoryInfoPage from "./pages/TerritoryInfoPage";
import TerritoryTabPage from "./pages/TerritoryTabPage";
import InitiativesPage from "./pages/InitiativesPage";
import SurveyFormPage from "./pages/SurveyFormPage";
import BlogPage from "./pages/BlogPage";
import ArticlePage from "./pages/ArticlePage";
import AilPage from "./pages/AilPage";

class Navigation extends Component {
  componentDidMount() {
    const { initialSite, userSessionStatus } = this.props;
    userSessionStatus();
    initialSite();
  }
  render() {
    const {
      loadingReducer: { loading_initial },
      defaultReducer: { texts },
    } = this.props;
    const menu1 = getLabelRoute(texts, "menu_1");
    const menu2 = getLabelRoute(texts, "menu_2");
    const menu4 = getLabelRoute(texts, "menu_4");
    const menu5 = getLabelRoute(texts, "menu_5");
    const menu6 = getLabelRoute(texts, "menu_6");
    const menuConecta = getLabelRoute(texts, "menu_conecta");

    return (
      <Router>
        {GA.init() && <GA.RouteTracker />}
        <ModalLoading visible={loading_initial} />
        {loading_initial === "hidden" && (
          <Switch>
            <Route exact path="/" component={AboutPage} />
            <Route exact path="/buscar/:words" component={SearchPage} />
            <Route exact path={`/${menu1.slug}`} component={AboutPage} />
            <Route
              exact
              path={`/${menu2.slug}/:type`}
              component={TerritoryInfoPage}
            />
            <Route exact path="/datos-territorio" component={TerritoryPage} />
            <Route
              exact
              path="/datos-territorio/ciudad/:id"
              component={TerritoryPage}
            />
            <Route
              exact
              path="/herramientas-metodologias"
              component={ToolsPage}
            />
            <Route
              exact
              path="/herramientas-metodologias/:id"
              component={ToolsPage}
            />
            <Route exact path={`/${menu4.slug}`} component={InitiativesPage} />
            <Route
              exact
              path={`/${menu4.slug}/:initiative`}
              component={InitiativesPage}
            />
            <Route
              exact
              path={`/${menu4.slug}/ciudad/:id`}
              component={EcosystemsPage}
            />
            <Route exact path={`/${menu5.slug}`} component={DocumentsPage} />
            <Route
              exact
              path={`/${menu5.slug}/:id`}
              component={DocumentsPage}
            />
            <Route
              exact
              path={`/${menuConecta.slug}`}
              component={ContactPage}
            />
            <Route
              exact
              path="/encuesta-formulario/:id"
              component={SurveyFormPage}
            />
            <Route exact path={`/${menu6.slug}`} component={AilPage} />
            <Route
              exact
              path={`/${menu6.slug}/momentos`}
              component={BlogPage}
            />
            <Route
              exact
              path={`/${menu6.slug}/momentos/pagina/:page`}
              component={BlogPage}
            />
            <Route
              exact
              path={`/${menu6.slug}/momentos/categoria/:category`}
              component={BlogPage}
            />
            <Route
              exact
              path={`/${menu6.slug}/momentos/categoria/:category/pagina/:page`}
              component={BlogPage}
            />
            <Route
              exact
              path={`/${menu6.slug}/momentos/articulo/:article`}
              component={ArticlePage}
            />
            <Route
              exact
              path={`/${menu6.slug}/:id`}
              component={NewsDetailPage}
            />
            <Route
              exact
              path={`/${menu2.slug}/grafico/:powerBi`}
              component={TerritoryPage}
            />
            <Route exact path={`/${menu2.slug}`} component={TerritoryTabPage} />
            <Route
              exact
              path={`/${menu2.slug}/pestana/:tab`}
              component={TerritoryTabPage}
            />
            <Route
              exact
              path={`/${menu2.slug}/pestana/:tab/contenido/:tab_item`}
              component={TerritoryTabPage}
            />
            <Route component={ErrorPage} />
          </Switch>
        )}
      </Router>
    );
  }
}

const mapStateToProps = ({ loadingReducer, defaultReducer }) => {
  return {
    loadingReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  initialSite,
  userSessionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
