import React from "react";
import "./css/FilterItem.css";

function FilterItem(props) {
  const { index, name, remove, color, sectionName } = props;
  return (
    <div className="FilterItem_content" style={{ backgroundColor: color }}>
      {sectionName && <>{sectionName}: </>}
      {name}
      <button onClick={() => remove(index)}>&times;</button>
    </div>
  );
}

FilterItem.defaultProps = {
  color: "#f59c00",
  sectionName: null,
};

export default FilterItem;
